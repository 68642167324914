import React from 'react';
import './CookieConsentBanner.scss';
import { Translation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type CookieConsentBannerProps = {
  cookieConsentStatus: boolean;
  handleCookieConsentChange: (incomingConsentChange: boolean) => void;
};

const CookieConsentBanner: React.FC<CookieConsentBannerProps> = (props) => {
  const buttonAction = () => {
    props.handleCookieConsentChange(true);
    localStorage.setItem('cookie-consent', JSON.stringify(true));
  };

  const location = useLocation();

  // if cookieConsentStatus is true OR the user is busy voting, do not show the cookie consent banner
  if (props.cookieConsentStatus || location?.pathname === '/aanestys') {
    return <></>;
  }

  return (
    <Translation>
      {(t) => (
        <div>
          {props.cookieConsentStatus !== true && (
            <div className='CookieConsentBanner'>
              <div className='CookieConsentBanner__consentTextContainer'>
                <p
                  className='CookieConsentBanner__consentTextContainer-consentText'
                  dangerouslySetInnerHTML={{
                    __html: t('CookieConsent.BannerText', {
                      returnObjects: true,
                      joinArrays: ' <br />',
                    }),
                  }}
                ></p>
              </div>
              <div className='CookieConsentBanner__showMoreContainer'>
                <Link
                  to='/kayttoehdot#evaste-kaytanto'
                  className='CookieConsentBanner__showMoreContainer-showMoreLink'
                >
                  {t('CookieConsent.ShowMore-link')}
                </Link>
              </div>
              <div className='CookieConsentBanner__AcceptButtonContainer'>
                <button
                  className='CookieConsentBanner__AcceptButtonContainer-AcceptButton'
                  onClick={() => {
                    buttonAction();
                  }}
                >
                  {t('CookieConsent.AcceptButton')}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </Translation>
  );
};

export default CookieConsentBanner;
