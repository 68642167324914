import React, { useContext } from 'react';
import RegisterLoginInfo, {
  RegisterLoginInfoValues,
} from './RegisterLoginInfo';
import RegisterMemberInfo, {
  RegisterMemberInfoValues,
} from './RegisterMemberInfo';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'containers/app/App';
import { RegisterState } from 'reducers/Auth';
import CommonForm from 'components/CommonForm';

enum RegisterViewState {
  LOGIN_INFO = 'LOGIN_INFO',
  MEMBER_INFO = 'MEMBER_INFO',
}

const Register: React.FC<
  RouteComponentProps & {
    onRegisterSucceed: () => void;
  }
> = (props) => {
  const [state, setState] = React.useState<RegisterViewState>(
    RegisterViewState.LOGIN_INFO
  );
  const { authService, authState, commonState, analyticsService } =
    useContext(AppContext);
  const [loginValues, setLoginValues] = React.useState<RegisterLoginInfoValues>(
    {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      token: '',
    }
  );
  const [memberInfoValues, setMemberInfoValues] =
    React.useState<RegisterMemberInfoValues>({
      club: null,
      timeOfBirth: null,
      address: '',
      phoneNumber: '',
      postOffice: '',
      postalCode: '',
      privacyPoliciesAccepted: false,
    });

  const onMemberInfoContinueClick = () => {
    authService!
      .register({
        email: loginValues.email,
        password: loginValues.password,
        confirmPassword: loginValues.passwordConfirm,
        accessToken: loginValues.token,
        club: memberInfoValues.club || 0,
        address: memberInfoValues.address,
        postOffice: memberInfoValues.postOffice,
        timeOfBirth: memberInfoValues.timeOfBirth
          ? memberInfoValues.timeOfBirth.getTime()
          : 0,
        phoneNumber: memberInfoValues.phoneNumber,
        postalCode: memberInfoValues.postalCode,
      })
      .then(() => {
        props.history.replace('/');
        props.onRegisterSucceed();
      })
      .catch(() => {});
  };

  const onLoginInfoContinueClick = () => {
    setState(RegisterViewState.MEMBER_INFO);
  };

  const onMemberInfoBackClick = () => {
    setState(RegisterViewState.LOGIN_INFO);
  };

  return (
    <CommonForm>
      {state === RegisterViewState.LOGIN_INFO ? (
        <RegisterLoginInfo
          onContinueClick={onLoginInfoContinueClick}
          query={props.location.search}
          values={loginValues}
          setValues={setLoginValues}
        />
      ) : (
        <RegisterMemberInfo
          onContinueClick={onMemberInfoContinueClick}
          onBackClick={onMemberInfoBackClick}
          values={memberInfoValues}
          setValues={setMemberInfoValues}
          clubs={commonState!.clubs}
          enabled={authState!.registerState !== RegisterState.Registering}
          analyticsService={analyticsService!}
        />
      )}
    </CommonForm>
  );
};

export default withRouter(Register);
