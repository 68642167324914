import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import VotingCategoryView from './votingCategory';
import { VotingCategory } from 'models/Voting/VotingCategory';
import { Candidate } from 'models/Candidate/Candidate';
import './VotingPage.scss';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';
import VotingSummaryView from './votingSummary';
import { AppContext } from 'containers/app/App';
import { VotingActionType, VoteState } from 'reducers/Voting';
import { VotingStep } from 'models/Voting/VotingStep';
import ConfirmModal from 'containers/modals/ConfirmModal';
import VotingInstructionsModal from 'containers/modals/VotingInstructionsModal';
import { getLatestVoting } from 'utils/voting';
import { VotingStatus } from 'models/Voting/VotingStatus';
import Globals from 'globals.json';

const isCategoryValid = (category: VotingCategory): boolean =>
  category.selectedCandidates.length >= category.minNumberOfChoices &&
  category.selectedCandidates.length <= category.maxNumberOfChoices;

const Voting: React.FC = () => {
  const { t } = useTranslation();
  const { votingService, votingState, votingDispatch } = useContext(AppContext);
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [showInstructions, setShowInstructions] =
    React.useState<boolean>(false);

  const onNextStep = () => {
    switch (votingState!.votingStep) {
      case VotingStep.Athlete:
        votingDispatch!({
          type: VotingActionType.VotingStepChange,
          payload: {
            votingStep: VotingStep.Team,
          },
        });
        break;
      case VotingStep.Team:
        votingDispatch!({
          type: VotingActionType.VotingStepChange,
          payload: {
            votingStep: VotingStep.Coach,
          },
        });
        break;
      case VotingStep.Coach:
        votingDispatch!({
          type: VotingActionType.VotingStepChange,
          payload: {
            votingStep: VotingStep.Young,
          },
        });
        break;
      case VotingStep.Young:
        votingDispatch!({
          type: VotingActionType.VotingStepChange,
          payload: {
            votingStep: VotingStep.Summary,
          },
        });
        break;
      case VotingStep.Summary:
        setShowConfirm(true);
        break;
    }
  };

  const onPrevStep = () => {
    switch (votingState!.votingStep) {
      case VotingStep.Team:
        votingDispatch!({
          type: VotingActionType.VotingStepChange,
          payload: {
            votingStep: VotingStep.Athlete,
          },
        });
        break;
      case VotingStep.Coach:
        votingDispatch!({
          type: VotingActionType.VotingStepChange,
          payload: {
            votingStep: VotingStep.Team,
          },
        });
        break;
      case VotingStep.Young:
        votingDispatch!({
          type: VotingActionType.VotingStepChange,
          payload: {
            votingStep: VotingStep.Coach,
          },
        });
        break;
      case VotingStep.Summary:
        votingDispatch!({
          type: VotingActionType.VotingStepChange,
          payload: {
            votingStep: VotingStep.Young,
          },
        });
        break;
    }
  };

  const onStepChange = (newStep: VotingStep) => {
    votingDispatch!({
      type: VotingActionType.VotingStepChange,
      payload: {
        votingStep: newStep,
      },
    });
  };

  const voting = getLatestVoting(votingState!.votings);
  const votingStatus = voting ? voting.getStatus() : VotingStatus.UNKNOWN;
  const hasBeenLoaded = voting ? voting.candidatesLoaded : true;
  const votingId = voting ? voting.id : 0;

  React.useEffect(() => {
    if (
      !hasBeenLoaded &&
      votingId > 0 &&
      votingStatus === VotingStatus.ONGOING
    ) {
      votingService!.getCandidates(votingId);
    }
  }, [votingService, votingId, hasBeenLoaded, votingStatus]);

  if (!voting || votingStatus !== VotingStatus.ONGOING) {
    return <></>;
  }

  const points = {
    '4': [5, 3, 1],
    '3': [5, 3, 1],
    '2': [5, 3, 1],
    '1': [10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
  };

  const currentCategory =
    votingState!.votingStep === VotingStep.Athlete
      ? voting.athleteCategory
      : votingState!.votingStep === VotingStep.Team
      ? voting.teamCategory
      : votingState!.votingStep === VotingStep.Coach
      ? voting.coachCategory
      : voting.youngCategory;

  const currentBgImage =
    votingState!.votingStep !== VotingStep.Summary
      ? currentCategory.bgImageSrc
      : Globals.EVENT_IMAGE;

  const currentInstructionsResource =
    votingState!.votingStep === VotingStep.Athlete
      ? 'Voting.AthleteOfTheYearInstructions'
      : votingState!.votingStep === VotingStep.Team
      ? 'Voting.TeamOfTheYearInstructions'
      : votingState!.votingStep === VotingStep.Coach
      ? 'Voting.CoachOfTheYearInstructions'
      : votingState!.votingStep === VotingStep.Young
      ? 'Voting.YoungAthleteOfTheYearInstructions'
      : '';

  const allCategoriesAreValid = [
    voting.athleteCategory,
    voting.coachCategory,
    voting.teamCategory,
    voting.youngCategory,
  ].every(isCategoryValid);

  const isVotingStepSelected = (
    index: number,
    votingStep: VotingStep
  ): boolean => {
    return votingStep - 1 === index;
  };

  const isVotingStepDisabled = (
    index: number,
    voteState: VoteState
  ): boolean => {
    const step = index + 1;

    switch (step) {
      case VotingStep.Athlete:
        return voteState === VoteState.SendingVotes;
      case VotingStep.Team:
        return (
          !isCategoryValid(voting.athleteCategory) ||
          voteState === VoteState.SendingVotes
        );
      case VotingStep.Coach:
        return (
          !isCategoryValid(voting.athleteCategory) ||
          !isCategoryValid(voting.teamCategory) ||
          voteState === VoteState.SendingVotes
        );
      case VotingStep.Young:
        return (
          !isCategoryValid(voting.athleteCategory) ||
          !isCategoryValid(voting.teamCategory) ||
          !isCategoryValid(voting.coachCategory) ||
          voteState === VoteState.SendingVotes
        );
      case VotingStep.Summary:
        return !allCategoriesAreValid;
    }

    return true;
  };

  const onVotingConfirmed = () => {
    votingService!.sendVotes(voting);
    setShowConfirm(false);
  };

  return (
    <div className='voting'>
      <img src={currentBgImage} className='voting__bg-image' alt='' />
      <div className='voting__title-step-container'>
        <div className='voting__instructions-container'>
          {votingState!.votingStep === VotingStep.Summary ? (
            <h1 className='voting__instructions-container__title site-header1'>
              {t('General.Summary')}
            </h1>
          ) : (
            <img
              className='voting__instructions-container__image'
              src={currentCategory.imageSrc}
              alt={t(currentCategory.nameResource)}
            />
          )}
          {currentInstructionsResource.length > 0 && (
            <>
              <p
                className='voting__instructions-container__instructions'
                dangerouslySetInnerHTML={{
                  __html: t(currentInstructionsResource, {
                    returnObjects: true,
                    joinArrays: ' <br />',
                    year: voting.year,
                  }),
                }}
              ></p>
              <div className='voting__instructions-container__link-container'>
                <a
                  href='#/'
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInstructions(true);
                  }}
                  className='voting__instructions-container__link-container__link'
                >
                  {t('Voting.VotingInstrctions')}
                </a>
              </div>
            </>
          )}
        </div>
        {votingState!.voteState !== VoteState.HasVoted && (
          <div className='voting__voting-step-container'>
            {votingState!.stepCategories.map(
              (stepCategory, stepCategoryIndex) => (
                <div
                  className={
                    'voting__voting-step-container__step' +
                    (isVotingStepSelected(
                      stepCategoryIndex,
                      votingState!.votingStep
                    )
                      ? ' voting__voting-step-container__step--selected'
                      : '') +
                    (isVotingStepDisabled(
                      stepCategoryIndex,
                      votingState!.voteState
                    )
                      ? ' voting__voting-step-container__step--disabled'
                      : '')
                  }
                  onClick={() => onStepChange(stepCategoryIndex + 1)}
                  role='button'
                  key={stepCategoryIndex}
                >
                  <p className='voting__voting-step-container__step__index'>
                    {stepCategoryIndex + 1}
                  </p>
                  <p className='voting__voting-step-container__step__name'>
                    {t(stepCategory)}
                  </p>
                </div>
              )
            )}
          </div>
        )}
      </div>
      {votingState!.votingStep !== VotingStep.Summary ? (
        <VotingCategoryView
          year={voting.year}
          points={points}
          step={votingState!.votingStep}
          categoryName={t(currentCategory.nameResource)}
          minNumberOfChoices={currentCategory.minNumberOfChoices}
          maxNumberOfChoices={currentCategory.maxNumberOfChoices}
          candidates={currentCategory.candidates}
          selectedCandidates={currentCategory.selectedCandidates}
          disabled={votingState!.voteState !== VoteState.NotVoted}
          amountOfOwnCandidates={currentCategory.amountOfOwnCandidates}
          onUpdateCandidates={(
            candidates: Candidate[],
            selectedCandidates: Candidate[]
          ) => {
            votingDispatch!({
              type: VotingActionType.UpdateVotingCategoryValues,
              payload: {
                votingId: voting.id,
                categoryCandidates: {
                  candidates: candidates,
                  selectedCandidates: selectedCandidates,
                },
              },
            });
          }}
        />
      ) : (
        <VotingSummaryView
          points={points}
          votingCategories={[
            voting.athleteCategory,
            voting.teamCategory,
            voting.coachCategory,
            voting.youngCategory,
          ]}
        />
      )}
      {votingState!.voteState !== VoteState.HasVoted && (
        <div className='voting__voting-navbar'>
          <div className='voting__voting-navbar__container'>
            {votingState!.voteState === VoteState.NotVoted ? (
              <>
                <button
                  className={
                    'voting__voting-navbar__container__nav-button voting__voting-navbar__container__nav-button--prev' +
                    (votingState!.votingStep <= 1
                      ? ' voting__voting-navbar__container__nav-button--hidden'
                      : '')
                  }
                  onClick={() => onPrevStep()}
                >
                  <Icon
                    path={mdiChevronLeft}
                    size={'30px'}
                    color='#000'
                    className='voting__voting-navbar__container__nav-button__icon'
                  />
                  <span className='voting__voting-navbar__container__nav-button__text'>
                    {t('General.PreviousCategory')}
                  </span>
                </button>
                <div className='voting__voting-navbar__container__info-container'>
                  <span className='voting__voting-navbar__container__info-container__category-text'>
                    {votingState!.votingStep !== VotingStep.Summary
                      ? `${t('General.Category')} ${votingState!.votingStep}: 
            ${t(currentCategory.nameResource)}`
                      : `${t('General.Summary')}:`}
                  </span>
                  <span className='voting__voting-navbar__container__info-container__selection-text'>
                    {votingState!.votingStep !== VotingStep.Summary
                      ? `${t('General.Selected')} ${
                          currentCategory.selectedCandidates.length
                        } / ${currentCategory.maxNumberOfChoices}`
                      : t('Voting.CheckAndAcceptVotes')}
                  </span>
                </div>
                <button
                  className={
                    'voting__voting-navbar__container__nav-button' +
                    (votingState!.votingStep === VotingStep.Summary
                      ? ' voting__voting-navbar__container__nav-button--send'
                      : ' voting__voting-navbar__container__nav-button--next')
                  }
                  disabled={
                    !isCategoryValid(currentCategory) ||
                    (votingState!.votingStep === VotingStep.Young &&
                      !allCategoriesAreValid)
                  }
                  onClick={() => onNextStep()}
                >
                  <span className='voting__voting-navbar__container__nav-button__text'>
                    {votingState!.votingStep === VotingStep.Summary
                      ? t('Voting.AcceptVotes')
                      : votingState!.votingStep === VotingStep.Young
                      ? t('General.Summary')
                      : t('General.NextCategory')}
                  </span>
                  <Icon
                    path={mdiChevronRight}
                    size={'30px'}
                    color='#fff'
                    className='voting__voting-navbar__container__nav-button__icon'
                  />
                </button>
              </>
            ) : (
              <div className='voting__voting-navbar__container__info-container'>
                <span className='voting__voting-navbar__container__info-container__selection-text'>
                  {t('Voting.SendingVotes')}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {showConfirm && (
        <ConfirmModal
          onClose={() => setShowConfirm(false)}
          onConfirm={onVotingConfirmed}
          textResource='Voting.VotingConfirm'
          secondaryTextResource='Voting.VotingConfirmSecondary'
        />
      )}
      {showInstructions && (
        <VotingInstructionsModal
          onClose={() => setShowInstructions(false)}
          year={voting.year}
        />
      )}
    </div>
  );
};

export default Voting;
