import React, { useContext } from 'react';
import './ResultsPage.scss';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'containers/app/App';
import Globals from 'globals.json';
import BorderTitle from 'components/BorderTitle';
import { getLatestVoting } from 'utils/voting';
import { VotingStatus } from 'models/Voting/VotingStatus';
import ResultSportGrid from 'components/Result/ResultSportGrid';
import ResultCandidateGrid from 'components/Result/ResultCandidateGrid';

const ResultsPage: React.FC = () => {
  const { t } = useTranslation();
  const { votingService, votingState } = useContext(AppContext);
  const voting = getLatestVoting(votingState!.votings);
  const votingStatus = voting ? voting.getStatus() : VotingStatus.UNKNOWN;

  const hasBeenLoaded = voting ? voting.resultsLoaded : false;
  const votingId = voting ? voting.id : 0;

  React.useEffect(() => {
    if (
      !hasBeenLoaded &&
      votingId > 0 &&
      votingStatus === VotingStatus.RESULTS_READY
    ) {
      votingService!.getResults([votingId]);
    }
  }, [votingService, hasBeenLoaded, votingId, votingStatus]);

  if (!voting || votingStatus !== VotingStatus.RESULTS_READY) {
    return <></>;
  }

  const mainCategories = voting!.result
    ? [
        {
          results: voting!.result.athletes,
          logoSrc: Globals.ATHLETE_CATEGORY_LOGO,
          nameResource: Globals.ATHLETE_CATEGORY_NAME,
          firstPlacesAvailable: true,
        },
        {
          results: voting!.result.teams,
          logoSrc: Globals.TEAM_CATEGORY_LOGO,
          nameResource: Globals.TEAM_CATEGORY_NAME,
          firstPlacesAvailable:
            voting.year >= Globals.FIRST_PLACES_AVAILABLE_YEAR,
        },
        {
          results: voting!.result.coaches,
          logoSrc: Globals.COACH_CATEGORY_LOGO,
          nameResource: Globals.COACH_CATEGORY_NAME,
          firstPlacesAvailable:
            voting.year >= Globals.FIRST_PLACES_AVAILABLE_YEAR,
        },
        {
          results: voting!.result.young,
          logoSrc: Globals.YOUNG_ATHLETE_CATEGORY_LOGO,
          nameResource: Globals.YOUNG_ATHLETE_CATEGORY_NAME,
          firstPlacesAvailable:
            voting.year >= Globals.FIRST_PLACES_AVAILABLE_YEAR,
        },
      ]
    : [];

  return (
    <div className='results'>
      <img src={Globals.EVENT_IMAGE} className='results__bg-image' alt='' />
      <div className='results__container'>
        <BorderTitle
          className='results__container__title'
          text={`${t('Results.Results')} ${voting!.year}`}
        />
        {mainCategories.map((category, categoryIndex) => (
          <ResultCandidateGrid
            key={categoryIndex}
            candidates={category.results}
            logo={category.logoSrc}
            nameResource={category.nameResource}
            showScores={true}
            showFirstPlaces={category.firstPlacesAvailable}
          />
        ))}

        {voting.result && voting.result!.bestInSports.length > 0 && (
          <ResultSportGrid
            title={`${t('General.BestInSports')} ${voting!.year}`}
            bestFromSportsCandidates={voting.result!.bestInSports}
          />
        )}
      </div>
    </div>
  );
};

export default ResultsPage;
