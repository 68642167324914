import Globals from 'globals.json';

export const parseQuery = (decodedQuery: string): string[][] =>
  decodedQuery
    .replace('?', '')
    .split('&')
    .map((parameter) => {
      const parameterParts = parameter.split('=');
      if (parameterParts.length < 2) {
        return [];
      }

      return [parameterParts[0], parameterParts[1]];
    })
    .filter((params) => params.length > 0);

export const getCandidateVotingImagePathWithS3Url = (
  candidateImageFileName: string
): string => {
  if (
    !candidateImageFileName ||
    candidateImageFileName.length === 0 ||
    candidateImageFileName.includes('default-candidate')
  ) {
    return Globals.DEFAULT_CANDIDATE_IMAGE;
  }

  return `${
    process.env.REACT_APP_S3_URL
  }/candidates/${candidateImageFileName.replace('.', '-100.')}`;
};

export const getCandidateResultImagePathWithS3Url = (
  candidateImageFileName: string
): string => {
  if (
    !candidateImageFileName ||
    candidateImageFileName.length === 0 ||
    candidateImageFileName.includes('default-candidate')
  ) {
    return Globals.DEFAULT_CANDIDATE_IMAGE;
  }

  return `${
    process.env.REACT_APP_S3_URL
  }/candidates/${candidateImageFileName.replace('.', '-600.')}`;
};

export const getCandidateFrontpageImagePathWithS3Url = (
  candidateImageFileName: string
): string => {
  if (
    !candidateImageFileName ||
    candidateImageFileName.length === 0 ||
    candidateImageFileName.includes('default-candidate')
  ) {
    return Globals.DEFAULT_CANDIDATE_IMAGE;
  }

  return `${
    process.env.REACT_APP_S3_URL
  }/candidates/${candidateImageFileName.replace('.', '-360.')}`;
};

export const getCandidateGalleryImagePathWithS3Url = (
  candidateImageFileName: string
): string => {
  if (
    !candidateImageFileName ||
    candidateImageFileName.length === 0 ||
    candidateImageFileName.includes('default-candidate')
  ) {
    return Globals.DEFAULT_CANDIDATE_IMAGE;
  }

  return `${
    process.env.REACT_APP_S3_URL
  }/candidates/${candidateImageFileName.replace('.', '-360.')}`;
};

export const getNewsImagePathWithS3Url = (
  newsImageFileName: string
): string => {
  let widthParam: string = '.';
  if (window.innerWidth < 601) {
    widthParam = '-600.';
  } else if (window.innerWidth < 1081) {
    widthParam = '-1080.';
  } else if (window.innerWidth < 1920) {
    widthParam = '-1920.';
  }

  return `${process.env.REACT_APP_S3_URL}/news/${newsImageFileName.replace(
    '.',
    widthParam
  )}`;
};

export const updateNewsImagePath = (newsImageFilePath: string): string => {
  if (window.innerWidth > 600 && window.innerWidth < 1081) {
    newsImageFilePath = newsImageFilePath.replace('-600.', '-1080.');
  } else if (window.innerWidth > 1080 && window.innerWidth < 1921) {
    newsImageFilePath = newsImageFilePath.replace('-600.', '-1920.');
    newsImageFilePath = newsImageFilePath.replace('-1080.', '-1920.');
  } else if (window.innerWidth > 1920) {
    newsImageFilePath = newsImageFilePath.replace('-600.', '.');
    newsImageFilePath = newsImageFilePath.replace('-1080.', '.');
    newsImageFilePath = newsImageFilePath.replace('-1920.', '.');
  }
  return newsImageFilePath;
};

export const getCommonImagePathWithS3Url = (
  commonImageFileName: string
): string => `${process.env.REACT_APP_S3_URL}/common/${commonImageFileName}`;
