import React from 'react';
import { useTranslation } from 'react-i18next';
import IconInput from 'components/IconInput';
import { mdiAccountCircle, mdiAt, mdiLockOpen } from '@mdi/js';
import { parseQuery } from 'utils/url';
import { isEmail } from 'utils/validation';
import Globals from 'globals.json';

export interface RegisterLoginInfoProps {
  onContinueClick: () => void;
  query: string;
  values: RegisterLoginInfoValues;
  setValues: React.Dispatch<React.SetStateAction<RegisterLoginInfoValues>>;
}

export interface RegisterLoginInfoValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  token: string;
}

interface QueryParam {
  firstName: string;
  lastName: string;
  email: string;
  token: string;
}

const RegisterLoginInfo: React.FC<RegisterLoginInfoProps> = (props) => {
  const { t } = useTranslation();
  const { values, setValues, query, onContinueClick } = props;

  React.useEffect(() => {
    try {
      //ewoiZmlyc3ROYW1lIjogIlRlcHBvIiwKImxhc3ROYW1lIjogIlRlc3RhYWphIiwKImVtYWlsIjogInRlcHBvLnRlc3RhYWphQHRlc3RhYWphdC5maSIsCiJ0b2tlbiI6ICJ0aGlzaXNhdG9rZW4iCn0%3D
      //https://www.browserling.com/tools/json-to-base64
      //https://www.browserling.com/tools/url-encode

      const queryParams = parseQuery(decodeURIComponent(query));
      const tokenQueryParam = queryParams.find((param) => param[0] === 't');
      if (tokenQueryParam && tokenQueryParam.length >= 2) {
        const tokenQueryParamValues: QueryParam = JSON.parse(
          window.atob(tokenQueryParam[1])
        );

        setValues({
          firstName: tokenQueryParamValues.firstName,
          lastName: tokenQueryParamValues.lastName,
          email: tokenQueryParamValues.email,
          token: tokenQueryParamValues.token,
          password: '',
          passwordConfirm: '',
        });
      }
    } catch (e) {}
  }, [query, setValues]);

  const isValid =
    values.email.length > 0 &&
    isEmail(values.email) &&
    values.password.length >= Globals.PASSWORD_MIN_LENGTH &&
    values.passwordConfirm.length >= Globals.PASSWORD_MIN_LENGTH &&
    values.password === values.passwordConfirm &&
    values.token.length > 0;

  return (
    <>
      <h3 className='common-form__form-container__form__title site-header3'>
        {t('General.Welcome')}!
      </h3>
      <IconInput
        className='common-form__form-container__form__input'
        placeholder={t('General.Firstname')}
        value={values.firstName}
        disabled={true}
        iconPath={mdiAccountCircle}
        onChange={(value: string) =>
          setValues({
            ...values,
            firstName: value,
          })
        }
        ariaLabel={t('General.Firstname')}
      />
      <IconInput
        className='common-form__form-container__form__input'
        placeholder={t('General.Lastname')}
        value={values.lastName}
        disabled={true}
        iconPath={mdiAccountCircle}
        onChange={(value: string) =>
          setValues({
            ...values,
            lastName: value,
          })
        }
        ariaLabel={t('General.Lastname')}
      />
      <IconInput
        type='email'
        className='common-form__form-container__form__input'
        placeholder={t('General.Email')}
        value={values.email}
        iconPath={mdiAt}
        disabled={true}
        onChange={(value: string) =>
          setValues({
            ...values,
            email: value,
          })
        }
        ariaLabel={t('General.Email')}
      />
      <p className='common-form__form-container__form__instructions'>
        {t('Register.PasswordInstructions', {
          passwordLength: Globals.PASSWORD_MIN_LENGTH,
        })}
      </p>
      <IconInput
        type='password'
        className='common-form__form-container__form__input'
        placeholder={t('Auth.Password')}
        value={values.password}
        iconPath={mdiLockOpen}
        onChange={(value: string) =>
          setValues({
            ...values,
            password: value,
          })
        }
        onSubmit={() => isValid && props.onContinueClick()}
        showValidState={true}
        isValid={values.password.length >= Globals.PASSWORD_MIN_LENGTH}
        autoComplete='new-password'
        ariaLabel={t('Auth.Password')}
      />
      <IconInput
        type='password'
        className='common-form__form-container__form__input'
        placeholder={t('Auth.PasswordConfirm')}
        value={values.passwordConfirm}
        iconPath={mdiLockOpen}
        onChange={(value: string) =>
          setValues({
            ...values,
            passwordConfirm: value,
          })
        }
        onSubmit={() => isValid && props.onContinueClick()}
        showValidState={true}
        isValid={
          values.password === values.passwordConfirm &&
          values.passwordConfirm.length >= Globals.PASSWORD_MIN_LENGTH
        }
        autoComplete='new-password'
        ariaLabel={t('Auth.PasswordConfirm')}
      />
      <button
        className='common-form__form-container__form__action-button'
        onClick={() => isValid && onContinueClick()}
        disabled={!isValid}
      >
        {t('General.Continue')}
      </button>
    </>
  );
};

export default RegisterLoginInfo;
