import { Candidate } from './Candidate';
import Globals from 'globals.json';

export class Athlete extends Candidate {
  getNames(): string[] {
    if (this.firstName2 && this.lastName2) {
      return [
        `${this.firstName} ${this.lastName}`,
        `${this.firstName2} ${this.lastName2}`,
      ];
    }

    return [`${this.firstName} ${this.lastName}`];
  }

  getSortKey(): string {
    return this.lastName || '';
  }
}

export const getEmptyAthlete = (): Athlete => {
  return new Athlete(
    0,
    '',
    '',
    '',
    '',
    Globals.DEFAULT_CANDIDATE_IMAGE,
    '',
    '',
    '',
    0,
    0
  );
};
