import React, {
  createContext,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './App.scss';
import Navbar from 'components/Navbar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NavbarItem } from 'components/Navbar/Navbar';
import Frontpage from 'containers/frontpage';
import Voting from 'containers/votingpage';
import { useTranslation } from 'react-i18next';
import { AuthService } from 'services/Auth';
import Globals from 'globals.json';
import {
  AuthReducer,
  AuthAction,
  AuthState,
  AuthInitState,
  LoginState,
} from 'reducers/Auth';
import LoginModal from 'containers/modals/LoginModal';
import Snackbar from 'components/Snackbar';
import {
  CommonState,
  CommonAction,
  CommonReducer,
  CommonInitState,
} from 'reducers/Common';
import { CommonService } from 'services/Common';
import { VotingService } from 'services/Voting';
import {
  VotingState,
  VotingAction,
  VotingReducer,
  VotingInitState,
} from 'reducers/Voting';
import CandidatePage from 'containers/candidatepage';
import HasVotedPage from 'containers/hasVotedPage';
import ResultsPage from 'containers/resultspage';
import { getLatestVoting } from 'utils/voting';
import { VotingStatus } from 'models/Voting/VotingStatus';
import ResultArchivePage, {
  ResultArchivePageType,
} from 'containers/resultArchivePage/ResultArchivePage';
import ResultBestInSportsPage from 'containers/resultBestInSportsPage';
import NewsPage from 'containers/news';
import Footer from 'components/Footer';
import { AnalyticsService } from 'services/Analytics';
import UsageTermsPage from 'containers/usageTermsPage';
import VotingInstructionsPage from 'containers/votingInstructionsPage';
import CommitteePage from 'containers/committeePage/CommitteePage';
import NewsArchivePage from 'containers/newsArchivePage/NewsArchivePage';
import ResetPasswordPage from 'containers/resetPasswordPage/ResetPasswordPage';
import CookieConsentBanner from 'containers/modals/CookieConsentBanner/CookieConsentBanner';
import Register from 'containers/auth/Register';
import ResetPassword from 'containers/auth/ResetPassword';

export interface IAppContext {
  authService?: AuthService;
  votingService?: VotingService;
  analyticsService?: AnalyticsService;

  authState?: AuthState;
  authDispatch?: React.Dispatch<AuthAction>;

  commonState?: CommonState;
  commonDispatch?: React.Dispatch<CommonAction>;

  votingState?: VotingState;
  votingDispatch?: React.Dispatch<VotingAction>;
}

export const AppContext = createContext<IAppContext>({});

const App: React.FC = () => {
  const { t } = useTranslation();
  const [showLogin, setShowLogin] = React.useState<boolean>(false);

  const [authState, authDispatch] = React.useReducer<
    React.Reducer<AuthState, AuthAction>
  >(AuthReducer, AuthInitState());

  const [commonState, commonDispatch] = React.useReducer<
    React.Reducer<CommonState, CommonAction>
  >(CommonReducer, CommonInitState());

  const [votingState, votingDispatch] = React.useReducer<
    React.Reducer<VotingState, VotingAction>
  >(VotingReducer, VotingInitState());

  const [cookieConsentStatus, setCookieConsentStatus] = useState(
    JSON.parse(localStorage.getItem('cookie-consent')!) || false
  );

  const handleCookieConsentChange: any = (incomingConsentChange: boolean) => {
    setCookieConsentStatus(incomingConsentChange);
  };

  const navbarLinks: NavbarItem[] = [
    {
      translationResource: 'General.Frontpage',
      link: '/',
    },
  ];

  const latestVoting = getLatestVoting(votingState!.votings);
  const latestVotingStatus = latestVoting
    ? latestVoting.getStatus()
    : VotingStatus.UNKNOWN;

  const isVotingIncomplete =
    latestVotingStatus === VotingStatus.ONGOING ||
    latestVotingStatus === VotingStatus.ENDED;
  const isVotingOnGoing = latestVotingStatus === VotingStatus.ONGOING;

  if (latestVotingStatus === VotingStatus.RESULTS_READY) {
    navbarLinks.push({
      translatioResourceArgs: { year: latestVoting!.year },
      translationResource: 'General.ResultsYear',
      link: '/tulokset',
    });
  }

  if (isVotingIncomplete) {
    navbarLinks.push({
      translationResource: 'General.CandidatesYear',
      translatioResourceArgs: { year: latestVoting!.year },
      link: '',
      subLinks: [
        {
          translationResource: Globals.ATHLETE_CATEGORY_NAME,
          link: '/vuoden-urheilija',
        },
        {
          translationResource: Globals.TEAM_CATEGORY_NAME,
          link: '/vuoden-joukkue',
        },
        {
          translationResource: Globals.COACH_CATEGORY_NAME,
          link: '/vuoden-valmentaja',
        },
        {
          translationResource: Globals.YOUNG_ATHLETE_CATEGORY_NAME,
          link: '/vuoden-nuori-urheilija',
        },
      ],
    });
  }

  if (latestVotingStatus !== VotingStatus.UNKNOWN) {
    navbarLinks.push({
      translationResource: 'General.Voting',
      link: '',
      subLinks: [
        {
          translationResource: 'VotingInstructions.VotingInstructions',
          link: '/aanestysohjeet',
        },
        {
          translationResource:
            'AthleteOfTheYearCommittee.AthleteOfTheYearCommittee',
          link: '/vuoden-urheilija-valiokunta',
        },
      ],
    });

    navbarLinks.push({
      translationResource: 'General.NewsArchive',
      link: '/uutisarkisto',
    });
  }

  if (
    votingState!.votings.filter(
      (voting) => voting.getStatus() === VotingStatus.RESULTS_READY
    ).length > 0
  ) {
    navbarLinks.push({
      translationResource: 'General.ResultsArchive',
      link: '',
      subLinks: [
        {
          translationResource: Globals.ATHLETE_CATEGORY_NAME,
          link: '/arkisto/vuoden-urheilija',
        },
        {
          translationResource: Globals.TEAM_CATEGORY_NAME,
          link: '/arkisto/vuoden-joukkue',
        },
        {
          translationResource: Globals.COACH_CATEGORY_NAME,
          link: '/arkisto/vuoden-valmentaja',
        },
        {
          translationResource: Globals.YOUNG_ATHLETE_CATEGORY_NAME,
          link: '/arkisto/vuoden-nuori-urheilija',
        },
        {
          translationResource: 'General.BestInSports',
          link: '/arkisto/lajien-parhaat',
        },
      ],
    });
  }

  if (authState.loginState === LoginState.LoggedIn && isVotingOnGoing) {
    navbarLinks.push({
      translationResource: 'General.VotingForm',
      link: '/aanestys',
    });
  }

  const [authService, commonService, votingService, analyticsService] =
    useMemo(() => {
      return [
        new AuthService(authDispatch, commonDispatch),
        new CommonService(commonDispatch),
        new VotingService(votingDispatch, commonDispatch, authDispatch),
        new AnalyticsService(),
      ];
    }, []);

  useEffect(() => {
    authService.validateSession().catch(function () {});
    commonService.getClubs().catch(function () {});
    commonService.getSports().catch(function () {});
    commonService.getNews().catch(function () {});
    votingService.getVotings().catch(function () {});

    if (process.env.REACT_APP_GA_TRACKING_ID && cookieConsentStatus) {
      analyticsService.initialize(process.env.REACT_APP_GA_TRACKING_ID || '');
      analyticsService.setCurrentPage(window.location.pathname);
      analyticsService.addPageView(window.location.pathname);
    }
  }, [
    authService,
    commonService,
    votingService,
    analyticsService,
    cookieConsentStatus,
  ]);

  const onRegisterSucceed = () => {
    if (
      authState!.loginState === LoginState.LoggedOut &&
      latestVotingStatus === VotingStatus.ONGOING
    ) {
      setShowLogin(true);
    }
  };

  const onResetPasswordSuccess = () => {
    if (
      authState!.loginState === LoginState.LoggedOut &&
      latestVotingStatus === VotingStatus.ONGOING
    ) {
      setShowLogin(true);
    }
  };

  const hasVotedInActiveVoting =
    latestVotingStatus === VotingStatus.ONGOING &&
    authState.user &&
    authState.user.hasVotedIn.some((votingId) => votingId === latestVoting!.id);

  return (
    <AppContext.Provider
      value={{
        authService,
        authState,
        authDispatch,
        commonState,
        votingService,
        votingState,
        votingDispatch,
        analyticsService,
      }}
    >
      <Router>
        <Navbar
          links={navbarLinks}
          onLoginClick={() => setShowLogin(true)}
          analyticsService={analyticsService}
          showLogin={latestVotingStatus === VotingStatus.ONGOING}
        />
        <div className='route-container'>
          <Switch>
            <Route exact path='/'>
              <Frontpage />
              <Footer showCodemateLogo={false} />
            </Route>
            <Route path='/vuoden-urheilija'>
              {isVotingIncomplete && (
                <>
                  <CandidatePage
                    votingYear={latestVoting!.year}
                    category={latestVoting!.athleteCategory}
                    votingId={latestVoting!.id}
                    hasBeenLoaded={latestVoting!.candidatesLoaded}
                    introductionTextResource='CandidatePage.AthleteOfTheYearIntroduction'
                    includeOwnCandidate={true}
                  />
                  <Footer showCodemateLogo />
                </>
              )}
            </Route>
            <Route path='/vuoden-joukkue'>
              {isVotingIncomplete && (
                <>
                  <CandidatePage
                    votingYear={latestVoting!.year}
                    category={latestVoting!.teamCategory}
                    votingId={latestVoting!.id}
                    hasBeenLoaded={latestVoting!.candidatesLoaded}
                    introductionTextResource='CandidatePage.TeamOfTheYearIntroduction'
                  />
                  <Footer showCodemateLogo />
                </>
              )}
            </Route>
            <Route path='/vuoden-valmentaja'>
              {isVotingIncomplete && (
                <>
                  <CandidatePage
                    votingYear={latestVoting!.year}
                    category={latestVoting!.coachCategory}
                    votingId={latestVoting!.id}
                    hasBeenLoaded={latestVoting!.candidatesLoaded}
                    introductionTextResource='CandidatePage.CoachOfTheYearIntroduction'
                  />
                  <Footer showCodemateLogo />
                </>
              )}
            </Route>
            <Route path='/vuoden-nuori-urheilija'>
              {isVotingIncomplete && (
                <>
                  <CandidatePage
                    votingYear={latestVoting!.year}
                    category={latestVoting!.youngCategory}
                    votingId={latestVoting!.id}
                    hasBeenLoaded={latestVoting!.candidatesLoaded}
                    introductionTextResource='CandidatePage.YoungAthleteOfTheYearIntroduction'
                  />
                  <Footer showCodemateLogo />
                </>
              )}
            </Route>
            <Route path='/aanestys'>
              {isVotingOnGoing &&
                authState.loginState === LoginState.LoggedIn &&
                (hasVotedInActiveVoting ? (
                  <HasVotedPage voting={latestVoting!} />
                ) : (
                  <Voting />
                ))}
            </Route>
            <Route path='/rekisterointi'>
              <Suspense fallback={''}>
                <Register onRegisterSucceed={onRegisterSucceed} />
              </Suspense>
            </Route>
            <Route path='/vaihda-salasana'>
              <Suspense fallback={''}>
                <ResetPassword
                  onResetPasswordSuccess={onResetPasswordSuccess}
                />
              </Suspense>
            </Route>
            <Route path='/salasanan-vaihto'>
              <ResetPasswordPage />
              <Footer showCodemateLogo />
            </Route>
            <Route path='/tulokset'>
              <ResultsPage />
              <Footer showCodemateLogo />
            </Route>
            <Route path='/arkisto/vuoden-urheilija'>
              <ResultArchivePage type={ResultArchivePageType.Athlete} />
              <Footer showCodemateLogo />
            </Route>
            <Route path='/arkisto/vuoden-joukkue'>
              <ResultArchivePage type={ResultArchivePageType.Team} />
              <Footer showCodemateLogo />
            </Route>
            <Route path='/arkisto/vuoden-valmentaja'>
              <ResultArchivePage type={ResultArchivePageType.Coach} />
              <Footer showCodemateLogo />
            </Route>
            <Route path='/arkisto/vuoden-nuori-urheilija'>
              <ResultArchivePage type={ResultArchivePageType.Young} />
              <Footer showCodemateLogo />
            </Route>
            <Route path='/arkisto/lajien-parhaat'>
              <ResultBestInSportsPage />
              <Footer showCodemateLogo />
            </Route>
            <Route path='/uutiset/'>
              <NewsPage />
              <Footer showCodemateLogo />
            </Route>
            <Route path='/kayttoehdot'>
              <Suspense fallback={''}>
                <UsageTermsPage />
                <Footer showCodemateLogo />
              </Suspense>
            </Route>
            <Route path='/aanestysohjeet'>
              <VotingInstructionsPage
                year={
                  latestVoting ? latestVoting.year : new Date().getFullYear()
                }
              />
              <Footer showCodemateLogo />
            </Route>
            <Route path='/uutisarkisto'>
              <NewsArchivePage />
              <Footer showCodemateLogo />
            </Route>
            <Route path='/vuoden-urheilija-valiokunta'>
              <CommitteePage
                year={
                  latestVoting ? latestVoting.year : new Date().getFullYear()
                }
              />
              <Footer showCodemateLogo />
            </Route>
            <Route path='*'>
              <Frontpage />
              <Footer showCodemateLogo={false} />
            </Route>
          </Switch>
        </div>

        {showLogin && (
          <LoginModal onClose={() => setShowLogin(false)}></LoginModal>
        )}
        <Snackbar
          text={t(
            commonState.snackbarMessageResource,
            commonState.snackbarMessageArgs
          )}
          eventTime={commonState.snackbarEventTime}
          timeout={commonState.snackbarTimeout}
          type={commonState.snackbarType}
        />
        <CookieConsentBanner
          cookieConsentStatus={cookieConsentStatus}
          handleCookieConsentChange={handleCookieConsentChange}
        />
      </Router>
    </AppContext.Provider>
  );
};

export default App;
