import React from 'react';

export const getStringArrayAsString = (array: string[]): string => {
  return array.join(', ');
};

export const getStringArrayAsJsxElements = (array: string[]): JSX.Element[] => {
  return array.map((name: string, index: number, array: string[]) => {
    return (
      <div key={index}>
        {name}
        {index + 1 < array.length && <br />}
      </div>
    );
  });
};
