import { Candidate } from 'models/Candidate/Candidate';
import { Voting } from 'models/Voting/Voting';
import { News } from 'models/News';

export const sortByStringsAsc = (list: string[]) =>
  list.sort((a: string, b: string): number => (a < b ? -1 : a > b ? 1 : 0));

export const sortByCandidatesBySportAsc = (candidates: Candidate[]) =>
  candidates.sort((a: Candidate, b: Candidate): number =>
    a.sport < b.sport ? -1 : a.sport > b.sport ? 1 : 0
  );
export const sortByCandidatesBySortKeyAsc = (candidates: Candidate[]) =>
  candidates.sort((a: Candidate, b: Candidate): number => {
    const candidateASortKey = a.getSortKey();
    const candidateBSortKey = b.getSortKey();

    return candidateASortKey < candidateBSortKey
      ? -1
      : candidateASortKey > candidateBSortKey
      ? 1
      : 0;
  });

export const sortVotingsByYearDesc = (votings: Voting[]): Voting[] => {
  return votings.sort((a: Voting, b: Voting): number =>
    a.year < b.year ? 1 : a.year > b.year ? -1 : 0
  );
};

export const sortNewsByCreatedDesc = (news: News[]): News[] => {
  return news.sort((a: News, b: News): number =>
    a.created < b.created ? 1 : a.created > b.created ? -1 : 0
  );
};
