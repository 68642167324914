import { Voting } from 'models/Voting/Voting';
import { Candidate } from 'models/Candidate/Candidate';

export const getLatestVoting = (votings: Voting[]): Voting | undefined => {
  if (votings.length === 0) {
    return undefined;
  }

  //Expecting votings to be sorted in descending order by year
  return votings[0];
};

export const categorizeVotingResultBestInSports = (
  bestInSports: Candidate[]
): { [id: string]: Candidate[] } | undefined => {
  let bestInTheSportsCategorized: { [id: string]: Candidate[] } | undefined =
    undefined;

  bestInSports.forEach((candidate) => {
    if (!candidate.sport || candidate.sport.length === 0) {
      return;
    }

    if (!bestInTheSportsCategorized) {
      bestInTheSportsCategorized = {};
    }

    const firstSportsLetter = candidate.sport[0].toUpperCase() as string;
    const category = bestInTheSportsCategorized
      ? bestInTheSportsCategorized[firstSportsLetter]
      : undefined;

    if (!category) {
      bestInTheSportsCategorized[firstSportsLetter] = [candidate];
    } else {
      bestInTheSportsCategorized[firstSportsLetter].push(candidate);
    }
  });

  return bestInTheSportsCategorized;
};
