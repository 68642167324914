import React from 'react';
import './IconInput.scss';
import Icon from '@mdi/react';
import { mdiAlertCircle } from '@mdi/js';

// https://materialdesignicons.com/

export interface IconInputProps {
  onChange: (value: string) => void;
  value: string;
  iconPath: string;
  type?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onSubmit?: () => void;
  reference?: any;
  showValidState?: boolean;
  isValid?: boolean;
  iconSize?: string;
  autoComplete?: string;
  ariaLabel?: string;
}

const IconInput: React.FC<IconInputProps> = (props) => {
  return (
    <div className={`${props.className || ''} icon-input-container`}>
      <Icon
        path={props.iconPath}
        size={props.iconSize || '24px'}
        color={props.showValidState && !props.isValid ? '#e95617' : '#ddb54c'}
        className='icon-input-container__icon'
      />
      <input
        type={props.type || 'text'}
        value={props.value}
        placeholder={props.placeholder || ''}
        onChange={(e) => props.onChange(e.target.value)}
        onKeyDown={(e) => {
          if (props.onSubmit && e.keyCode === 13) {
            // Enter
            props.onSubmit();
          }
        }}
        className={
          'icon-input-container__input' +
          (props.showValidState && !props.isValid
            ? ' icon-input-container__input--invalid'
            : '')
        }
        disabled={props.disabled || false}
        ref={props.reference}
        autoComplete={props.autoComplete || 'on'}
        aria-label={props.ariaLabel}
      ></input>
      {props.showValidState && !props.isValid && (
        <Icon
          path={mdiAlertCircle}
          size={props.iconSize || '24px'}
          color='#e95617'
          className='icon-input-container__alert-icon'
        />
      )}
    </div>
  );
};

export default IconInput;
