import { Athlete, getEmptyAthlete } from 'models/Candidate/Athlete';
import { Team, getEmptyTeam } from 'models/Candidate/Team';
import { Coach, getEmptyCoach } from 'models/Candidate/Coach';
import { Young, getEmptyYoung } from 'models/Candidate/Young';
import axios from 'axios';
import Globals from 'globals.json';
import { VotingValues } from 'models/Voting/VotingValues';
import { VotingAction, VotingActionType } from 'reducers/Voting';
import { Voting } from 'models/Voting/Voting';
import { CommonAction, CommonActionType } from 'reducers/Common';
import { SnackbarType } from 'components/Snackbar/Snackbar';
import { AuthAction, AuthActionType } from 'reducers/Auth';
import { VotingResult } from 'models/Voting/VotingResult';
import { getCommonImagePathWithS3Url } from 'utils/url';
import { getStringArrayAsString } from 'utils/array';

export class VotingService {
  votingDispatch!: React.Dispatch<VotingAction>;
  commonDispatch!: React.Dispatch<CommonAction>;
  authDispatch!: React.Dispatch<AuthAction>;

  constructor(
    votingDispatch: React.Dispatch<VotingAction>,
    commonDispatch: React.Dispatch<CommonAction>,
    authDispatch: React.Dispatch<AuthAction>
  ) {
    this.votingDispatch = votingDispatch;
    this.commonDispatch = commonDispatch;
    this.authDispatch = authDispatch;
  }

  getCandidates(votingId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/candidates?votingId=${votingId}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: Globals.TIMEOUT,
          }
        )
        .then((response) => {
          const candidates = {
            athletes: [],
            teams: [],
            coaches: [],
            young: [],
          } as VotingValues;

          response.data.forEach((candidate: any) => {
            if (candidate.category === 'individual') {
              candidates.athletes.push(
                new Athlete(
                  candidate.id,
                  candidate.firstName,
                  candidate.lastName,
                  candidate.sport,
                  candidate.achievements,
                  candidate.imageUrl,
                  candidate.firstName2,
                  candidate.lastName2,
                  candidate.team
                )
              );
            } else if (candidate.category === 'team') {
              candidates.teams.push(
                new Team(
                  candidate.id,
                  candidate.firstName,
                  candidate.lastName,
                  candidate.sport,
                  candidate.achievements,
                  candidate.imageUrl,
                  candidate.firstName2,
                  candidate.lastName2,
                  candidate.team
                )
              );
            } else if (candidate.category === 'coach') {
              candidates.coaches.push(
                new Coach(
                  candidate.id,
                  candidate.firstName,
                  candidate.lastName,
                  candidate.sport,
                  candidate.achievements,
                  candidate.imageUrl,
                  candidate.firstName2,
                  candidate.lastName2,
                  candidate.team
                )
              );
            } else if (candidate.category === 'young') {
              candidates.young.push(
                new Young(
                  candidate.id,
                  candidate.firstName,
                  candidate.lastName,
                  candidate.sport,
                  candidate.achievements,
                  candidate.imageUrl,
                  candidate.firstName2,
                  candidate.lastName2,
                  candidate.team
                )
              );
            }
          });

          this.votingDispatch!({
            type: VotingActionType.InitVotingCategoryValues,
            payload: {
              initVotingValues: candidates,
              votingId: votingId,
            },
          });

          resolve();
        })
        .catch((err) => {
          this.commonDispatch!({
            type: CommonActionType.ShowSnackbar,
            payload: {
              snackbarMessage: 'Error.CandidateLoadingFailed',
            },
          });

          reject(err);
        });
    });
  }

  getResults(votingIds: number[]): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/voting/results?ids=${votingIds.join(
            ','
          )}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: Globals.TIMEOUT,
          }
        )
        .then((response) => {
          if (response.data.messageId) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: `Error.${response.data.messageId}`,
              },
            });

            reject();
            return;
          }

          const results: { [id: number]: VotingResult | undefined } = {};

          votingIds.forEach((votingId) => {
            const result = {
              athletes: [],
              teams: [],
              coaches: [],
              young: [],
              bestInSports: [],
            } as VotingResult;

            const votingData = response.data[votingId];

            if (votingData && votingData.individual) {
              votingData.individual.forEach((data: any) => {
                if (data.candidate) {
                  result.athletes.push(
                    new Athlete(
                      data.candidate.id,
                      data.candidate.firstName,
                      data.candidate.lastName,
                      data.candidate.sport,
                      data.candidate.achievements,
                      data.candidate.imageUrl,
                      data.candidate.firstName2,
                      data.candidate.lastName2,
                      data.candidate.team,
                      data.points || 0,
                      data.firstPlaces || 0
                    )
                  );
                } else {
                  result.athletes.push(getEmptyAthlete());
                }
              });
            }

            if (votingData && votingData.team) {
              votingData.team.forEach((data: any) => {
                if (data.candidate) {
                  result.teams.push(
                    new Team(
                      data.candidate.id,
                      data.candidate.firstName,
                      data.candidate.lastName,
                      data.candidate.sport,
                      data.candidate.achievements,
                      data.candidate.imageUrl,
                      data.candidate.firstName2,
                      data.candidate.lastName2,
                      data.candidate.team,
                      data.points || 0,
                      data.firstPlaces || 0
                    )
                  );
                } else {
                  result.athletes.push(getEmptyTeam());
                }
              });
            }

            if (votingData && votingData.coach) {
              votingData.coach.forEach((data: any) => {
                if (data.candidate) {
                  result.coaches.push(
                    new Coach(
                      data.candidate.id,
                      data.candidate.firstName,
                      data.candidate.lastName,
                      data.candidate.sport,
                      data.candidate.achievements,
                      data.candidate.imageUrl,
                      data.candidate.firstName2,
                      data.candidate.lastName2,
                      data.candidate.team,
                      data.points || 0,
                      data.firstPlaces || 0
                    )
                  );
                } else {
                  result.athletes.push(getEmptyCoach());
                }
              });
            }

            if (votingData && votingData.young) {
              votingData.young.forEach((data: any) => {
                if (data.candidate) {
                  result.young.push(
                    new Young(
                      data.candidate.id,
                      data.candidate.firstName,
                      data.candidate.lastName,
                      data.candidate.sport,
                      data.candidate.achievements,
                      data.candidate.imageUrl,
                      data.candidate.firstName2,
                      data.candidate.lastName2,
                      data.candidate.team,
                      data.points || 0,
                      data.firstPlaces || 0
                    )
                  );
                } else {
                  result.athletes.push(getEmptyYoung());
                }
              });
            }

            if (votingData && votingData.sport) {
              votingData.sport.forEach((data: any) => {
                if (data.candidate) {
                  result.bestInSports.push(
                    new Athlete(
                      data.candidate.id,
                      data.candidate.firstName,
                      data.candidate.lastName,
                      data.candidate.sport,
                      data.candidate.achievements,
                      data.candidate.imageUrl,
                      data.candidate.firstName2,
                      data.candidate.lastName2,
                      data.candidate.team,
                      data.points || 0,
                      data.firstPlaces || 0
                    )
                  );
                } else {
                  result.athletes.push(getEmptyAthlete());
                }
              });
            }

            results[votingId] = votingData ? result : undefined;
          });

          this.votingDispatch!({
            type: VotingActionType.UpdateResultValuesForVoting,
            payload: {
              votingResults: results,
            },
          });

          resolve();
        })
        .catch((err) => {
          this.commonDispatch!({
            type: CommonActionType.ShowSnackbar,
            payload: {
              snackbarMessage: 'Error.ResultLoadingFailed',
            },
          });
          reject(err);
        });
    });
  }

  getVotings(): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/voting`, {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: Globals.TIMEOUT,
        })
        .then((response) => {
          this.votingDispatch!({
            type: VotingActionType.UpdateVotings,
            payload: {
              votings: response.data.map((voting: any) => {
                return new Voting(
                  voting.id,
                  voting.year,
                  new Date(voting.startTime),
                  new Date(voting.endTime),
                  voting.resultsDone,
                  {
                    nameResource: Globals.ATHLETE_CATEGORY_NAME,
                    minNumberOfChoices: 5,
                    maxNumberOfChoices: 10,
                    candidates: [],
                    selectedCandidates: [],
                    amountOfOwnCandidates: 1,
                    imageSrc: Globals.ATHLETE_CATEGORY_LOGO,
                    bgImageSrc: getCommonImagePathWithS3Url(
                      Globals.ATHLETE_CATEGORY_BG
                    ),
                  },
                  {
                    nameResource: Globals.TEAM_CATEGORY_NAME,
                    minNumberOfChoices: 3,
                    maxNumberOfChoices: 3,
                    candidates: [],
                    selectedCandidates: [],
                    amountOfOwnCandidates: 0,
                    imageSrc: Globals.TEAM_CATEGORY_LOGO,
                    bgImageSrc: getCommonImagePathWithS3Url(
                      Globals.TEAM_CATEGORY_BG
                    ),
                  },
                  {
                    nameResource: Globals.COACH_CATEGORY_NAME,
                    minNumberOfChoices: 3,
                    maxNumberOfChoices: 3,
                    candidates: [],
                    selectedCandidates: [],
                    amountOfOwnCandidates: 0,
                    imageSrc: Globals.COACH_CATEGORY_LOGO,
                    bgImageSrc: getCommonImagePathWithS3Url(
                      Globals.COACH_CATEGORY_BG
                    ),
                  },
                  {
                    nameResource: Globals.YOUNG_ATHLETE_CATEGORY_NAME,
                    minNumberOfChoices: 3,
                    maxNumberOfChoices: 3,
                    candidates: [],
                    selectedCandidates: [],
                    amountOfOwnCandidates: 0,
                    imageSrc: Globals.YOUNG_ATHLETE_CATEGORY_LOGO,
                    bgImageSrc: getCommonImagePathWithS3Url(
                      Globals.YOUNG_ATHLETE_CATEGORY_BG
                    ),
                  }
                );
              }),
            },
          });

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendVotes(voting: Voting): Promise<void> {
    this.votingDispatch!({
      type: VotingActionType.SendingVotes,
      payload: {},
    });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/votes`,
          {
            votingId: voting.id,
            individual: voting.athleteCategory.selectedCandidates.map(
              (candidate, candidateIndex) => {
                let candidateValue: any = {
                  placement: candidateIndex + 1,
                  candidateId: candidate.id,
                  category: 'individual',
                };

                if (candidate.isOwnCandidate()) {
                  candidateValue.votersCandidate = {
                    firstName: candidate.firstName,
                    lastName: candidate.lastName,
                    firstName2: candidate.firstName2,
                    lastName2: candidate.lastName2,
                    sport: candidate.sport,
                    achievements: getStringArrayAsString(
                      candidate.achievements
                    ),
                  };
                }

                return candidateValue;
              }
            ),
            team: voting.teamCategory.selectedCandidates.map(
              (candidate, candidateIndex) => {
                return {
                  placement: candidateIndex + 1,
                  candidateId: candidate.id,
                  category: 'team',
                };
              }
            ),
            coach: voting.coachCategory.selectedCandidates.map(
              (candidate, candidateIndex) => {
                return {
                  placement: candidateIndex + 1,
                  candidateId: candidate.id,
                  category: 'coach',
                };
              }
            ),
            young: voting.youngCategory.selectedCandidates.map(
              (candidate, candidateIndex) => {
                return {
                  placement: candidateIndex + 1,
                  candidateId: candidate.id,
                  category: 'young',
                };
              }
            ),
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: Globals.TIMEOUT,
            withCredentials: true,
          }
        )
        .then(() => {
          this.votingDispatch!({
            type: VotingActionType.SendingVotesSucceed,
            payload: {},
          });

          this.commonDispatch!({
            type: CommonActionType.ShowSnackbar,
            payload: {
              snackbarMessage: 'Voting.VotesSent',
              snackbarType: SnackbarType.Success,
            },
          });

          this.authDispatch!({
            type: AuthActionType.UserHasVoted,
            payload: {
              votingId: voting.id,
            },
          });

          resolve();
        })
        .catch((err) => {
          this.votingDispatch!({
            type: VotingActionType.SendingVotesFailed,
            payload: {},
          });

          if (err.response && err.response.status === 403) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: `Error.${err.response.data.messageId}`,
              },
            });
          } else if (err.response && err.response.status === 401) {
            //Session ended
            this.authDispatch!({
              type: AuthActionType.Logout,
              payload: {
                redirectToHome: true,
              },
            });
          } else {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.SendingVotesFailed',
              },
            });
          }

          reject(err);
        });
    });
  }
}
