export abstract class Candidate {
  id: number;
  firstName: string;
  lastName: string;
  firstName2?: string;
  lastName2?: string;
  sport: string;
  team?: string;
  achievements: string[];
  imageUrl: string;
  points?: number;
  firstPlaces?: number;

  constructor(
    id: number,
    firstName: string,
    lastName: string,
    sport: string,
    achievements: string,
    imageUrl: string,
    firstName2?: string,
    lastName2?: string,
    team?: string,
    points?: number,
    firstPlaces?: number
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.sport = sport;
    this.achievements = achievements ? achievements.split('|') : [];
    this.imageUrl = imageUrl;

    this.firstName2 = firstName2;
    this.lastName2 = lastName2;
    this.team = team;
    this.points = points;
    this.firstPlaces = firstPlaces;
  }

  abstract getSortKey(): string;
  abstract getNames(): string[];

  isOwnCandidate(): boolean {
    return this.id === 0;
  }
}
