import React from 'react';
import './VotingInstructionsPage.scss';
import { useTranslation } from 'react-i18next';
import Globals from 'globals.json';
export interface VotingInstructionsPageProps {
  year: number;
}

const VotingInstructionsPage: React.FC<VotingInstructionsPageProps> = ({
  year,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <img
        src={Globals.EVENT_IMAGE}
        className='voting-instructions-page-bg-image'
        alt=''
      />
      <div className='voting-instructions-page'>
        <h3 className='voting-instructions-page__title site-header3'>
          {t('VotingInstructions.VotingInstructions')}
        </h3>
        <div className='voting-instructions-page__content-container'>
          <p className='voting-instructions-page__content-container__title'>
            {t('VotingInstructions.AthleteOfTheYearTitle')}
          </p>
          <p
            className='voting-instructions-page__content-container__text'
            dangerouslySetInnerHTML={{
              __html: t('VotingInstructions.AthleteOfTheYearInstructions', {
                returnObjects: true,
                joinArrays: ' <br />',
                year: year,
              }),
            }}
          ></p>
          <p className='voting-instructions-page__content-container__title'>
            {t('VotingInstructions.TeamOfTheYearTitle')}
          </p>
          <p
            className='voting-instructions-page__content-container__text'
            dangerouslySetInnerHTML={{
              __html: t('VotingInstructions.TeamOfTheYearInstructions', {
                returnObjects: true,
                joinArrays: ' <br />',
                year: year,
              }),
            }}
          ></p>
          <p className='voting-instructions-page__content-container__title'>
            {t('VotingInstructions.CoachOfTheYearTitle')}
          </p>
          <p
            className='voting-instructions-page__content-container__text'
            dangerouslySetInnerHTML={{
              __html: t('VotingInstructions.CoachOfTheYearInstructions', {
                returnObjects: true,
                joinArrays: ' <br />',
                year: year,
              }),
            }}
          ></p>
          <p className='voting-instructions-page__content-container__title'>
            {t('VotingInstructions.YoungAthleteOfTheYearTitle')}
          </p>
          <p
            className='voting-instructions-page__content-container__text'
            dangerouslySetInnerHTML={{
              __html: t(
                'VotingInstructions.YoungAthleteOfTheYearInstructions',
                {
                  returnObjects: true,
                  joinArrays: ' <br />',
                  year: year,
                }
              ),
            }}
          ></p>
        </div>
      </div>
    </>
  );
};

export default VotingInstructionsPage;
