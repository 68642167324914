import React from 'react';
import './VotingCategoryView.scss';
import { Translation } from 'react-i18next';
import { Candidate } from 'models/Candidate/Candidate';
import { mdiDragVertical, mdiCloseCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { getStringArrayAsString } from 'utils/array';
import OwnCandidateModal from 'containers/modals/OwnCandidateModal';
import { Athlete } from 'models/Candidate/Athlete';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragStart,
  DragUpdate,
} from 'react-beautiful-dnd';
import { sortByCandidatesBySortKeyAsc } from 'utils/sort';
import Globals from 'globals.json';
import { getCandidateVotingImagePathWithS3Url } from 'utils/url';
import ConfirmModal from 'containers/modals/ConfirmModal';
import BorderTitle from 'components/BorderTitle';

export interface VotingCategoryViewState {
  dragIndex: number | null;
  showOwnCandidateModal: boolean;
  dragCandidates: Candidate[];
  showRemoveCandidateModal: boolean;
  removeCandidateIndex: number | null;
}

interface VotingCategoryViewProps {
  points: {
    [key: number]: Object[];
  };
  step: number;
  categoryName: string;
  minNumberOfChoices: number;
  maxNumberOfChoices: number;
  candidates: Candidate[];
  selectedCandidates: Candidate[];
  disabled: boolean;
  amountOfOwnCandidates: number;
  year: number;

  onUpdateCandidates: (
    candidates: Candidate[],
    selectedCandidated: Candidate[]
  ) => void;
}

class VotingCategoryView extends React.Component<
  VotingCategoryViewProps,
  VotingCategoryViewState
> {
  state: VotingCategoryViewState = {
    dragIndex: null,
    showOwnCandidateModal: false,
    dragCandidates: [],
    showRemoveCandidateModal: false,
    removeCandidateIndex: null,
  };

  onSelectCandidate = (index: number) => {
    if (this.props.disabled) {
      return;
    }

    const newSelectArray = [...this.props.candidates];
    newSelectArray.splice(index, 1);

    this.props.onUpdateCandidates(newSelectArray, [
      ...this.props.selectedCandidates,
      this.props.candidates[index],
    ]);
  };

  onRemoveCandidate = () => {
    if (this.props.disabled) {
      return;
    }

    if (this.state.removeCandidateIndex !== null) {
      let index = this.state.removeCandidateIndex;

      const newSelectedArray = [...this.props.selectedCandidates];
      newSelectedArray.splice(index, 1);

      this.props.onUpdateCandidates(
        this.props.selectedCandidates[index].isOwnCandidate()
          ? this.props.candidates
          : [...this.props.candidates, this.props.selectedCandidates[index]],
        newSelectedArray
      );
    }

    this.onRemoveCandidateModalClose();
  };

  onRemoveCandidateModalOpen = (index: number) => {
    this.setState({
      ...this.state,
      showRemoveCandidateModal: true,
      removeCandidateIndex: index,
    });
  };

  onRemoveCandidateModalClose = () => {
    this.setState({
      ...this.state,
      showRemoveCandidateModal: false,
      removeCandidateIndex: null,
    });
  };

  onOwnCandidateModalOpen = () => {
    this.setState({
      ...this.state,
      showOwnCandidateModal: true,
    });
  };

  onOwnCandidateModalClose = () => {
    this.setState({
      ...this.state,
      showOwnCandidateModal: false,
    });
  };

  onOwnCandidateModalSubmit = (
    firstName: string,
    lastName: string,
    firstName2: string,
    lastName2: string,
    sport: string,
    argument: string
  ) => {
    this.props.onUpdateCandidates(this.props.candidates, [
      ...this.props.selectedCandidates,
      new Athlete(
        0,
        firstName,
        lastName,
        sport,
        argument,
        Globals.DEFAULT_CANDIDATE_IMAGE,
        firstName2,
        lastName2
      ),
    ]);

    this.setState({
      ...this.state,
      showOwnCandidateModal: false,
    });
  };

  onDragStart = (result: DragStart) => {
    if (this.props.disabled) {
      return;
    }

    this.setState({
      ...this.state,
      dragIndex: result.source.index,
      dragCandidates: this.props.selectedCandidates,
    });
  };

  onDragEnd = () => {
    this.props.onUpdateCandidates(
      this.props.candidates,
      this.state.dragCandidates
    );

    this.setState({
      ...this.state,
      dragIndex: null,
      dragCandidates: [],
    });
  };

  onDragUpdate = (result: DragUpdate) => {
    if (this.props.disabled) {
      return;
    }

    if (
      !result.destination ||
      result.destination.index === this.state.dragIndex ||
      this.state.dragIndex === null
    ) {
      return;
    }

    const candidate = this.props.selectedCandidates[this.state.dragIndex];
    const newCandidates = [...this.props.selectedCandidates];
    newCandidates.splice(this.state.dragIndex, 1);
    newCandidates.splice(result.destination!.index, 0, candidate);

    this.setState({
      ...this.state,
      dragIndex: result.source!.index,
      dragCandidates: newCandidates,
    });
  };

  render() {
    const amountOfSelectedOwnCandidates = this.props.selectedCandidates.filter(
      (candidate) => candidate.isOwnCandidate()
    ).length;

    return (
      <Translation>
        {(t) => (
          <>
            <div className='voting-category'>
              <div className='voting-category__list-container'>
                <DragDropContext
                  onDragEnd={this.onDragEnd}
                  onDragStart={this.onDragStart}
                  onDragUpdate={this.onDragUpdate}
                >
                  <Droppable droppableId='droppable'>
                    {(provided: any) => (
                      <>
                        <ul
                          className='voting-category__list-container__list'
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {this.props.selectedCandidates.map(
                            (selection, selectionIndex) => (
                              <Draggable
                                draggableId={selectionIndex.toString()}
                                index={selectionIndex}
                                key={selectionIndex}
                                shouldRespectForcePress={false}
                                disableInteractiveElementBlocking={true}
                              >
                                {(p: any) => (
                                  <li
                                    className='voting-category__list-container__list__item'
                                    ref={p.innerRef}
                                    {...p.dragHandleProps}
                                    {...p.draggableProps}
                                  >
                                    <div className='voting-category__list-container__list-item__order-text'>
                                      <div>
                                        <p className='voting-category__list-container__list-item__order-text-number'>
                                          #{selectionIndex + 1}
                                        </p>
                                        <p className='voting-category__list-container__list-item__order-text-position'>
                                          <>
                                            {
                                              this.props.points[
                                                this.props.step
                                              ][selectionIndex]
                                            }{' '}
                                            p.
                                          </>
                                        </p>
                                      </div>
                                    </div>
                                    <div className='voting-category__list-container__list-item__container'>
                                      <img
                                        className='voting-category__list-container__list-item__container__image'
                                        src={
                                          selection.isOwnCandidate()
                                            ? selection.imageUrl
                                            : getCandidateVotingImagePathWithS3Url(
                                                selection.imageUrl
                                              )
                                        }
                                        alt={getStringArrayAsString(
                                          selection.getNames()
                                        )}
                                      />
                                      <div className='voting-category__list-container__list-item__container__text-container voting-category__list-container__list-item__container__text-container--selected'>
                                        <div className='voting-category__list-container__list-item__container__text-container__name'>
                                          {getStringArrayAsString(
                                            selection.getNames()
                                          )}
                                        </div>
                                        <div className='voting-category__list-container__list-item__container__text-container__info'>
                                          <p>{selection.sport}</p>
                                          <div className='voting-category__list-container__list-item__container__text-container__info__separator' />
                                          <p>
                                            {getStringArrayAsString(
                                              selection.achievements
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className='voting-category__list-container__list-item__container__controls-container voting-category__list-container__list-item__container__controls-container--selected'>
                                        <div
                                          className='voting-category__list-container__list-item__container__controls-container__icon-button voting-category__list-container__list-item__container__controls-container__icon-button--click'
                                          role='button'
                                          onClick={() =>
                                            this.onRemoveCandidateModalOpen(
                                              selectionIndex
                                            )
                                          }
                                        >
                                          <Icon
                                            path={mdiCloseCircleOutline}
                                            size={'40px'}
                                            color='#fff'
                                            className='voting-category__list-container__list-item__container__controls-container__icon-button__icon'
                                          />
                                        </div>
                                        <div className='voting-category__list-container__list-item__container__controls-container__icon-button voting-category__list-container__list-item__container__controls-container__icon-button--drag'>
                                          <div className='voting-category__list-container__list-item__container__controls-container__icon-button__drag-layout'></div>
                                          <Icon
                                            path={mdiDragVertical}
                                            size={'40px'}
                                            color='#fff'
                                            className='voting-category__list-container__list-item__container__controls-container__icon-button__icon'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            )
                          )}
                          <div className='voting-category__list-container__drag-placeholder'>
                            {provided.placeholder}
                          </div>
                        </ul>
                      </>
                    )}
                  </Droppable>
                </DragDropContext>
                {!this.props.disabled && (
                  <>
                    <BorderTitle
                      className='voting-category__list-container__selectable-title'
                      text={t('General.Candidates', { year: this.props.year })}
                    />
                    <ul className='voting-category__list-container__list'>
                      {sortByCandidatesBySortKeyAsc(this.props.candidates).map(
                        (selection, selectionIndex) => (
                          <li
                            className='voting-category__list-container__list__item'
                            key={selectionIndex}
                          >
                            <div className='voting-category__list-container__list-item__container'>
                              <img
                                className='voting-category__list-container__list-item__container__image'
                                src={getCandidateVotingImagePathWithS3Url(
                                  selection.imageUrl
                                )}
                                alt={getStringArrayAsString(
                                  selection.getNames()
                                )}
                              />
                              <div className='voting-category__list-container__list-item__container__text-container'>
                                <div className='voting-category__list-container__list-item__container__text-container__name'>
                                  {getStringArrayAsString(selection.getNames())}
                                </div>
                                <div className='voting-category__list-container__list-item__container__text-container__info'>
                                  <p>{selection.sport}</p>
                                  <div className='voting-category__list-container__list-item__container__text-container__info__separator' />
                                  <p>
                                    {getStringArrayAsString(
                                      selection.achievements
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className='voting-category__list-container__list-item__container__controls-container'>
                                <button
                                  className='voting-category__list-container__list-item__container__controls-container__choose-button'
                                  onClick={() =>
                                    this.onSelectCandidate(selectionIndex)
                                  }
                                  disabled={
                                    this.props.maxNumberOfChoices <=
                                    this.props.selectedCandidates.length
                                  }
                                >
                                  {t('General.Choose')}
                                </button>
                              </div>
                            </div>
                          </li>
                        )
                      )}
                      {[
                        ...Array(
                          this.props.amountOfOwnCandidates -
                            amountOfSelectedOwnCandidates
                        ),
                      ].map((_, index) => (
                        <li
                          className='voting-category__list-container__list__item'
                          key={index}
                        >
                          <div className='voting-category__list-container__list-item__container'>
                            <img
                              className='voting-category__list-container__list-item__container__image'
                              src={Globals.DEFAULT_CANDIDATE_IMAGE}
                              alt={t('General.OwnCandidate')}
                            />
                            <div className='voting-category__list-container__list-item__container__text-container'>
                              <div className='voting-category__list-container__list-item__container__text-container__name'>
                                {t('General.OwnCandidate')}
                              </div>
                            </div>
                            <div className='voting-category__list-container__list-item__container__controls-container'>
                              <button
                                className='voting-category__list-container__list-item__container__controls-container__choose-button'
                                onClick={() => this.onOwnCandidateModalOpen()}
                                disabled={
                                  this.props.maxNumberOfChoices <=
                                  this.props.selectedCandidates.length
                                }
                              >
                                {t('General.Choose')}
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
            {this.state.showOwnCandidateModal && (
              <OwnCandidateModal
                onClose={this.onOwnCandidateModalClose}
                onSubmit={this.onOwnCandidateModalSubmit}
                year={this.props.year}
              />
            )}
            {this.state.showRemoveCandidateModal && (
              <ConfirmModal
                onClose={this.onRemoveCandidateModalClose}
                onConfirm={this.onRemoveCandidate}
                textResource='General.ConfirmCandidateRemove'
              />
            )}
          </>
        )}
      </Translation>
    );
  }
}

export default VotingCategoryView;
