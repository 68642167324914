import { Candidate } from './Candidate';
import Globals from 'globals.json';

export class Coach extends Candidate {
  getNames(): string[] {
    return [`${this.firstName} ${this.lastName}`];
  }

  getSortKey(): string {
    return this.lastName || '';
  }
}

export const getEmptyCoach = (): Coach => {
  return new Coach(
    0,
    '',
    '',
    '',
    '',
    Globals.DEFAULT_CANDIDATE_IMAGE,
    '',
    '',
    '',
    0,
    0
  );
};
