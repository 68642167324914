import { Candidate } from './Candidate';
import Globals from 'globals.json';

export class Team extends Candidate {
  getNames(): string[] {
    return [this.team || ''];
  }

  getSortKey(): string {
    return this.team || '';
  }
}

export const getEmptyTeam = (): Team => {
  return new Team(
    0,
    '',
    '',
    '',
    '',
    Globals.DEFAULT_CANDIDATE_IMAGE,
    '',
    '',
    '',
    0,
    0
  );
};
