import React from 'react';
import { useTranslation } from 'react-i18next';
import { Club } from 'models/Club';
import IconInput from 'components/IconInput';
import {
  mdiCalendar,
  mdiHome,
  mdiCity,
  mdiAccountMultiple,
  mdiPhone,
  mdiHomeCity,
} from '@mdi/js';
import IconSelect from 'components/IconSelect';
import IconDatepicker from 'components/IconDatepicker';
import './RegisterMemberInfo.scss';
import { AnalyticsService } from 'services/Analytics';

export interface RegisterMemberInfoProps {
  onContinueClick: () => void;
  onBackClick: () => void;
  values: RegisterMemberInfoValues;
  setValues: React.Dispatch<React.SetStateAction<RegisterMemberInfoValues>>;
  clubs: Club[];
  enabled: boolean;
  analyticsService: AnalyticsService;
}

export interface RegisterMemberInfoValues {
  club: number | null;
  timeOfBirth: Date | null;
  address: string;
  postOffice: string;
  postalCode: string;
  phoneNumber: string;
  privacyPoliciesAccepted: boolean;
}

const RegisterMemberInfo: React.FC<RegisterMemberInfoProps> = (props) => {
  const { t } = useTranslation();
  const { values, setValues, onContinueClick, onBackClick, clubs } = props;
  const isValid =
    values.club !== null &&
    values.timeOfBirth !== null &&
    values.address.length > 0 &&
    values.postOffice.length > 0 &&
    values.postalCode.length > 0 &&
    values.phoneNumber.length > 0 &&
    values.privacyPoliciesAccepted;

  const timeInput = React.useRef<any>(null);
  const timeOfBirthText = t('General.TimeOfBirth');

  React.useEffect(() => {
    if (timeInput && timeInput.current) {
      timeInput.current.input.setAttribute('aria-label', timeOfBirthText);
    }
  }, [timeOfBirthText]);

  return (
    <>
      <h3 className='common-form__form-container__form__title site-header3'>
        {t('Register.YourMemberInfo')}
      </h3>
      <IconDatepicker
        className='common-form__form-container__form__input'
        placeholder={timeOfBirthText}
        value={values.timeOfBirth}
        iconPath={mdiCalendar}
        disabled={!props.enabled}
        onChange={(value: Date | null) =>
          setValues({
            ...values,
            timeOfBirth: value,
          })
        }
        dateFormat={t('Time.DateFormat')}
        yearItemCount={100}
        maxDate={new Date()}
        showYearDropdown={true}
        isValid={values.timeOfBirth !== null}
        showValidState
        reference={timeInput}
      />
      <IconInput
        className='common-form__form-container__form__input'
        placeholder={t('General.StreetAddress')}
        value={values.address}
        iconPath={mdiHome}
        disabled={!props.enabled}
        onChange={(value: string) =>
          setValues({
            ...values,
            address: value,
          })
        }
        isValid={values.address.length > 0}
        showValidState
        ariaLabel={t('General.StreetAddress')}
      />
      <IconInput
        className='common-form__form-container__form__input'
        placeholder={t('General.PostOffice')}
        value={values.postOffice}
        iconPath={mdiCity}
        disabled={!props.enabled}
        onChange={(value: string) =>
          setValues({
            ...values,
            postOffice: value,
          })
        }
        isValid={values.postOffice.length > 0}
        showValidState
        ariaLabel={t('General.PostOffice')}
      />
      <IconInput
        className='common-form__form-container__form__input'
        placeholder={t('General.PostalCode')}
        value={values.postalCode}
        iconPath={mdiHomeCity}
        disabled={!props.enabled}
        onChange={(value: string) =>
          setValues({
            ...values,
            postalCode: value,
          })
        }
        isValid={values.postalCode.length > 0}
        showValidState
        ariaLabel={t('General.PostalCode')}
      />
      <IconInput
        className='common-form__form-container__form__input'
        placeholder={t('General.PhoneNumber')}
        value={values.phoneNumber}
        iconPath={mdiPhone}
        disabled={!props.enabled}
        onChange={(value: string) =>
          setValues({
            ...values,
            phoneNumber: value,
          })
        }
        isValid={values.phoneNumber.length > 0}
        showValidState
        ariaLabel={t('General.PhoneNumber')}
      />
      <IconSelect
        className='common-form__form-container__form__input'
        value={values.club ? values.club.toString() : ''}
        iconPath={mdiAccountMultiple}
        placeholderText={t('General.Club')}
        disabled={!props.enabled}
        options={clubs.map((club) => {
          return {
            value: club.id.toString(),
            text: club.name,
          };
        })}
        onChange={(value: string) =>
          setValues({
            ...values,
            club: Number(value),
          })
        }
        isValid={values.club !== null}
        showValidState
        ariaLabel={t('General.Club')}
      />
      <div className='privacy-policy'>
        <a
          href='/kayttoehdot'
          target='_blank'
          rel='noopener noreferrer'
          className='privacy-policy__link'
          aria-label={t('UsageTerms.ReadUsageTerms')}
          onClick={() => {
            props.analyticsService.addEvent(
              'LinkOpened',
              'Privacy policies register page'
            );
          }}
        >
          {t('UsageTerms.ReadUsageTerms')}
        </a>
      </div>
      <div className='accept-privacy-policies'>
        <label htmlFor='acceptPrivacyPolicies'>
          <input
            type='checkbox'
            name='acceptPrivacyPolicies'
            id='acceptPrivacyPolicies'
            disabled={!props.enabled}
            onChange={() =>
              setValues({
                ...values,
                privacyPoliciesAccepted: !values.privacyPoliciesAccepted,
              })
            }
            checked={values.privacyPoliciesAccepted}
            aria-label={t('Register.AcceptPrivacyPolicies')}
          />
          {t('Register.AcceptPrivacyPolicies')}
        </label>
      </div>
      <div className='common-form__form-container__form__multi-button-container'>
        <button
          className='common-form__form-container__form__back-button'
          onClick={() => onBackClick()}
          disabled={!props.enabled}
        >
          {t('General.Back')}
        </button>
        <button
          className='common-form__form-container__form__action-button'
          onClick={() => onContinueClick()}
          disabled={!props.enabled || !isValid}
        >
          {t('General.Ready')}!
        </button>
      </div>
    </>
  );
};

export default RegisterMemberInfo;
