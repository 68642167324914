import React from 'react';
import './ResultCandidateGrid.scss';
import {
  getStringArrayAsJsxElements,
  getStringArrayAsString,
} from 'utils/array';
import { Candidate } from 'models/Candidate/Candidate';
import { useTranslation } from 'react-i18next';
import { getCandidateResultImagePathWithS3Url } from 'utils/url';

export interface ResultCandidateGridProps {
  candidates: Candidate[];
  logo?: string;
  nameResource: string;
  showScores: boolean;
  showFirstPlaces: boolean;
}

const maxAmountOfCandidatesInGrid = 11;

const ResultCandidateGrid: React.FC<ResultCandidateGridProps> = (props) => {
  const { t } = useTranslation();
  let needsShowAllButton: boolean = false;
  if (props.candidates.length > maxAmountOfCandidatesInGrid) {
    needsShowAllButton = true;
  }
  // showAll tracks whether all results are being shown (if the user has pressed the show all button)
  const [showAll, setShowAll] = React.useState<boolean>(false);
  return (
    <div className='candidate-container'>
      {props.candidates
        .filter(
          (_, candidateIndex) =>
            showAll || candidateIndex < maxAmountOfCandidatesInGrid
        )
        .map((candidate, candidateIndex) => (
          <div className='candidate-container__item' key={candidateIndex}>
            {candidateIndex === 0 && (
              <div className='candidate-container__item--first'>
                <img
                  src={getCandidateResultImagePathWithS3Url(candidate.imageUrl)}
                  className='candidate-container__item--first__image'
                  alt={getStringArrayAsString(candidate.getNames())}
                />
                <div className='candidate-container__item--first__fade' />
                <div className='candidate-container__item--first__summary'>
                  {props.logo && (
                    <img
                      className='candidate-container__item--first__summary__category-image'
                      src={props.logo}
                      alt={t(props.nameResource)}
                    />
                  )}
                  {!props.logo && (
                    <h3 className='candidate-container__item--first__summary__category-title site-header3'>
                      {t(props.nameResource)}
                    </h3>
                  )}
                  <div className='candidate-container__item--first__summary__name'>
                    {getStringArrayAsJsxElements(candidate.getNames())}
                  </div>
                  <p className='candidate-container__item--first__summary__sport'>
                    {candidate.sport}
                  </p>
                  {props.showScores && (
                    <div className='candidate-container__item--first__summary__points-container'>
                      <div className='candidate-container__item--first__summary__points-container__points'>
                        <p>{t('Results.Points')}</p>
                        <p>{candidate.points}</p>
                      </div>
                      {props.showFirstPlaces && (
                        <div className='candidate-container__item--first__summary__points-container__points'>
                          <p>{t('Results.FirstPlaces')}</p>
                          <p>{candidate.firstPlaces}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {candidateIndex > 0 &&
              (showAll || candidateIndex < maxAmountOfCandidatesInGrid) && (
                <div className='candidate-container__item--other'>
                  <h3 className='candidate-container__item--other__order site-header3'>
                    #
                    {candidate.points ===
                    props.candidates[candidateIndex - 1].points
                      ? props.candidates.findIndex(
                          (firstCandidateToHaveThesePointsAndFirstPlaces) =>
                            firstCandidateToHaveThesePointsAndFirstPlaces.points ===
                              props.candidates[candidateIndex].points &&
                            firstCandidateToHaveThesePointsAndFirstPlaces.firstPlaces ===
                              props.candidates[candidateIndex].firstPlaces
                        ) + 1
                      : candidateIndex + 1}
                  </h3>
                  <div className='candidate-container__item--other__info-container'>
                    <div className='candidate-container__item--other__info-container__name'>
                      {getStringArrayAsJsxElements(candidate.getNames())}
                    </div>
                    <p className='candidate-container__item--other__info-container__sport'>
                      {candidate.sport}
                    </p>
                  </div>

                  {props.showScores && (
                    <div className='candidate-container__item--other__points-container'>
                      <p className='candidate-container__item--other__points-container__points'>
                        {`${t('Results.Points')}: ${candidate.points}`}
                      </p>
                      {props.showFirstPlaces && (
                        <p className='candidate-container__item--other__points-container__points'>
                          {`${t('Results.FirstPlaces')}: ${
                            candidate.firstPlaces
                          }`}
                        </p>
                      )}
                      <p className='candidate-container__item--other__points-container__points-mobile'>
                        {`${t('Results.PointsShort')}: ${candidate.points}`}
                      </p>
                      {props.showFirstPlaces && (
                        <p className='candidate-container__item--other__points-container__points-mobile'>
                          {`${t('Results.FirstPlacesShort')}: ${
                            candidate.firstPlaces
                          }`}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}
          </div>
        ))}
      {needsShowAllButton && (
        <div
          className='candidate-container__horizontal-ruler'
          onClick={(e) => {
            setShowAll(!showAll);
          }}
        >
          <div className='candidate-container__horizontal-ruler__left-decoration'></div>
          <div className='candidate-container__horizontal-ruler__center-text'>
            {!showAll ? 'Näytä kaikki \u2304' : 'Näytä vähemmän \u2303'}
          </div>
          <div className='candidate-container__horizontal-ruler__right-decoration'></div>
        </div>
      )}
    </div>
  );
};

export default ResultCandidateGrid;
