import { SnackbarType } from 'components/Snackbar/Snackbar';
import { Club } from 'models/Club';
import { News } from 'models/News';
import { sortNewsByCreatedDesc } from 'utils/sort';

export interface CommonState {
  snackbarMessageResource: string;
  snackbarMessageArgs: any;
  snackbarEventTime: number;
  snackbarTimeout: number;
  snackbarType: SnackbarType;

  clubs: Club[];
  sports: string[];
  news: News[];
}

export enum CommonActionType {
  ShowSnackbar = 'showSnackbar',
  UpdateClubs = 'updateClubs',
  UpdateSports = 'updateSports',
  UpdateNews = 'updateNews',
}

export interface CommonAction {
  type: CommonActionType;
  payload: {
    snackbarMessage?: string;
    snackbarMessageArgs?: any;
    snackbarTimeout?: number;
    snackbarType?: SnackbarType;

    clubs?: Club[];
    sports?: string[];
    news?: News[];
  };
}

export const CommonInitState = (): CommonState => {
  return {
    snackbarMessageResource: '',
    snackbarMessageArgs: null,
    snackbarEventTime: 0,
    snackbarTimeout: 0,
    snackbarType: SnackbarType.Error,

    clubs: [],
    sports: [],
    news: [],
  };
};

export const CommonReducer = (state: CommonState, action: CommonAction) => {
  switch (action.type) {
    case CommonActionType.ShowSnackbar:
      return {
        ...state,
        snackbarMessageResource: action.payload.snackbarMessage || '',
        snackbarMessageArgs: action.payload.snackbarMessageArgs,
        snackbarEventTime: new Date().getTime(),
        snackbarTimeout:
          action.payload.snackbarTimeout === undefined
            ? 4000
            : action.payload.snackbarTimeout,
        snackbarType: action.payload.snackbarType || SnackbarType.Error,
      };
    case CommonActionType.UpdateClubs:
      return {
        ...state,
        clubs: action.payload.clubs || [],
      };
    case CommonActionType.UpdateSports:
      return {
        ...state,
        sports: action.payload.sports || [],
      };
    case CommonActionType.UpdateNews:
      return {
        ...state,
        news: sortNewsByCreatedDesc(action.payload.news || []),
      };
  }
  return state;
};
