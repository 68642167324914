import React from 'react';
import './ResultSportGrid.scss';
import { getStringArrayAsJsxElements } from 'utils/array';
import BorderTitle from 'components/BorderTitle';
import { Candidate } from 'models/Candidate/Candidate';
import { sortByStringsAsc, sortByCandidatesBySportAsc } from 'utils/sort';
import { categorizeVotingResultBestInSports } from 'utils/voting';

export interface ResultSportGridProps {
  bestFromSportsCandidates: Candidate[];
  title: string;
}

const ResultSportGrid: React.FC<ResultSportGridProps> = (props) => {
  const bestFromSportsCategorized = categorizeVotingResultBestInSports(
    props.bestFromSportsCandidates
  );

  return (
    <div className='best-from-sports'>
      {props.title.length > 0 && (
        <BorderTitle className='best-from-sports__title' text={props.title} />
      )}
      <div className='best-from-sports__grid'>
        {sortByStringsAsc(Object.keys(bestFromSportsCategorized!)).map(
          (bestInSportsCategoryKey, bestInSportsCategoryKeyIndex) => (
            <div
              className='best-from-sports__grid__item'
              key={bestInSportsCategoryKeyIndex}
            >
              <h3 className='best-from-sports__grid__item__category-letter site-header3'>
                {bestInSportsCategoryKey}
              </h3>

              {sortByCandidatesBySportAsc(
                bestFromSportsCategorized![bestInSportsCategoryKey]
              ).map((candidate: Candidate, candidateIndex: number) => (
                <div
                  className='best-from-sports__grid__item__candidate'
                  key={candidateIndex}
                >
                  <div className='best-from-sports__grid__item__candidate__sport'>
                    {candidate.sport}
                  </div>
                  <div className='best-from-sports__grid__item__candidate__name'>
                    {getStringArrayAsJsxElements(candidate.getNames())}
                  </div>
                  <div className='best-from-sports__grid__item__candidate__team'>
                    {candidate.team}
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ResultSportGrid;
