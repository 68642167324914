import { Candidate } from './Candidate';
import Globals from 'globals.json';

export class Young extends Candidate {
  getNames(): string[] {
    return [`${this.firstName} ${this.lastName}`];
  }

  getSortKey(): string {
    return this.lastName || '';
  }
}

export const getEmptyYoung = (): Young => {
  return new Young(
    0,
    '',
    '',
    '',
    '',
    Globals.DEFAULT_CANDIDATE_IMAGE,
    '',
    '',
    '',
    0,
    0
  );
};
