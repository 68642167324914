import { VotingCategory } from './VotingCategory';
import { Candidate } from 'models/Candidate/Candidate';
import { VotingResult } from './VotingResult';
import { VotingStatus } from './VotingStatus';

export const VALID_VOTING_START_LIMIT = 1000 * 60 * 60 * 24 * 14; //2 weeks

export class Voting {
  id: number;
  year: number;
  startTime: Date;
  endTime: Date;
  resultsDone: boolean;

  candidatesLoaded: boolean;
  resultsLoaded: boolean;

  athleteCategory: VotingCategory;
  coachCategory: VotingCategory;
  teamCategory: VotingCategory;
  youngCategory: VotingCategory;

  result?: VotingResult;

  constructor(
    id: number,
    year: number,
    startTime: Date,
    endTime: Date,
    resultsDone: boolean,
    athleteCategory: VotingCategory,
    teamCategory: VotingCategory,
    coachCategory: VotingCategory,
    youngCategory: VotingCategory
  ) {
    this.id = id;
    this.year = year;
    this.startTime = startTime;
    this.endTime = endTime;
    this.resultsDone = resultsDone;

    this.candidatesLoaded = false;
    this.resultsLoaded = false;

    this.athleteCategory = athleteCategory;
    this.teamCategory = teamCategory;
    this.coachCategory = coachCategory;
    this.youngCategory = youngCategory;
  }

  isValid() {
    return this.endTime && this.isStartTimeInValidRange();
  }

  setCandidates(
    athletes: Candidate[],
    teams: Candidate[],
    coaches: Candidate[],
    young: Candidate[]
  ) {
    this.candidatesLoaded = true;

    this.athleteCategory.candidates = athletes;
    this.athleteCategory.selectedCandidates = [];

    this.teamCategory.candidates = teams;
    this.teamCategory.selectedCandidates = [];

    this.coachCategory.candidates = coaches;
    this.coachCategory.selectedCandidates = [];

    this.youngCategory.candidates = young;
    this.youngCategory.selectedCandidates = [];
  }

  updateAthleteCategoryCandidates(
    candidates: Candidate[],
    selectedCandidates: Candidate[]
  ) {
    if (!this.athleteCategory) {
      return;
    }

    this.athleteCategory.candidates = candidates;
    this.athleteCategory.selectedCandidates = selectedCandidates;
  }

  updateTeamCategoryCandidates(
    candidates: Candidate[],
    selectedCandidates: Candidate[]
  ) {
    if (!this.teamCategory) {
      return;
    }

    this.teamCategory.candidates = candidates;
    this.teamCategory.selectedCandidates = selectedCandidates;
  }

  updateCoachCategoryCandidates(
    candidates: Candidate[],
    selectedCandidates: Candidate[]
  ) {
    if (!this.coachCategory) {
      return;
    }

    this.coachCategory.candidates = candidates;
    this.coachCategory.selectedCandidates = selectedCandidates;
  }

  updateYoungCategoryCandidates(
    candidates: Candidate[],
    selectedCandidates: Candidate[]
  ) {
    if (!this.youngCategory) {
      return;
    }

    this.youngCategory.candidates = candidates;
    this.youngCategory.selectedCandidates = selectedCandidates;
  }

  setResults(result: VotingResult | undefined) {
    this.resultsLoaded = true;
    this.result = result;
  }

  getStatus(): VotingStatus {
    if (this.isValid()) {
      if (!this.hasStarted()) {
        return VotingStatus.COMING_SOON;
      }

      if (this.isActive()) {
        return VotingStatus.ONGOING;
      }

      if (this.hasEnded() && !this.resultsDone) {
        return VotingStatus.ENDED;
      }

      if (this.hasEnded() && this.resultsDone) {
        return VotingStatus.RESULTS_READY;
      }
    }

    return VotingStatus.UNKNOWN;
  }

  private isActive(): boolean {
    const timeNow = new Date();

    return (
      this.endTime &&
      this.startTime &&
      this.endTime > timeNow &&
      this.startTime < timeNow
    );
  }

  private isStartTimeInValidRange() {
    const timeNow = new Date();
    return (
      this.startTime &&
      timeNow.getTime() + VALID_VOTING_START_LIMIT > this.startTime.getTime()
    );
  }

  private hasStarted(): boolean {
    const timeNow = new Date();
    return this.startTime && timeNow > this.startTime;
  }

  private hasEnded(): boolean {
    const timeNow = new Date();

    return this.endTime && this.endTime <= timeNow;
  }
}
