import { User } from 'models/User';

export enum LoginState {
  LoggedOut,
  LoggedIn,
  LoggingIn,
}

export enum RegisterState {
  None,
  Registering,
  Succeed,
  Error,
}

export enum ForgotPasswordState {
  None,
  Requesting,
  Succeed,
  Error,
}

export enum RequestRegisterState {
  None,
  Requesting,
  Succeed,
  Error,
}

export interface AuthState {
  loginState: LoginState;
  registerState: RegisterState;
  forgotPasswordState: ForgotPasswordState;
  requestRegisterState: RequestRegisterState;
  user?: User;
}

export enum AuthActionType {
  Login = 'login',
  Logout = 'logout',
  LoggingIn = 'loggingIn',

  Registering = 'registering',
  RegisterSucceed = 'registerSucceed',
  RegisterFailed = 'registerFailed',

  ForgotPassword = 'forgotPassword',
  ForgotPasswordSucceed = 'forgotPasswordSucceed',
  ForgotPasswordFailed = 'forgotPasswordFailed',

  RequestingRegister = 'requestingRegister',
  RequestingRegisterSucceed = 'requestingRegisterSucceed',
  RequestingRegisterFailed = 'requestingRegisterFailed',

  SessionValidated = 'sessionValidated',
  UserHasVoted = 'userHasVoted',
}

export interface AuthAction {
  type: AuthActionType;
  payload: {
    user?: User;
    registerFailedMessage?: string;
    redirectToHome?: boolean;
    votingId?: number;
  };
}

export const AuthInitState = (): AuthState => {
  return {
    loginState: LoginState.LoggedOut,
    registerState: RegisterState.None,
    forgotPasswordState: ForgotPasswordState.None,
    requestRegisterState: RequestRegisterState.None,
  };
};

export const AuthReducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case AuthActionType.Login:
      localStorage.setItem('user', JSON.stringify(action.payload.user));

      return {
        ...state,
        loginState: LoginState.LoggedIn,
        user: action.payload.user,
      };
    case AuthActionType.Logout:
      localStorage.removeItem('user');

      if (action.payload.redirectToHome) {
        window.location.href = '/';
      }

      return {
        ...state,
        loginState: LoginState.LoggedOut,
        user: undefined,
      };
    case AuthActionType.LoggingIn:
      return {
        ...state,
        loginState: LoginState.LoggingIn,
      };
    case AuthActionType.Registering:
      return {
        ...state,
        registerState: RegisterState.Registering,
      };
    case AuthActionType.RegisterSucceed:
      return {
        ...state,
        registerState: RegisterState.Succeed,
      };
    case AuthActionType.RegisterFailed:
      return {
        ...state,
        registerState: RegisterState.Error,
      };
    case AuthActionType.ForgotPassword:
      return {
        ...state,
        forgotPasswordState: ForgotPasswordState.Requesting,
      };
    case AuthActionType.ForgotPasswordSucceed:
      return {
        ...state,
        forgotPasswordState: ForgotPasswordState.Succeed,
      };
    case AuthActionType.ForgotPasswordFailed:
      return {
        ...state,
        forgotPasswordState: ForgotPasswordState.Error,
      };
    case AuthActionType.RequestingRegister:
      return {
        ...state,
        requestRegisterState: RequestRegisterState.Requesting,
      };
    case AuthActionType.RequestingRegisterSucceed:
      return {
        ...state,
        requestRegisterState: RequestRegisterState.Succeed,
      };
    case AuthActionType.RequestingRegisterFailed:
      return {
        ...state,
        requestRegisterState: RequestRegisterState.Error,
      };
    case AuthActionType.SessionValidated:
      const userAsJsonString = localStorage.getItem('user');
      const user = userAsJsonString ? JSON.parse(userAsJsonString) : undefined;

      return {
        ...state,
        loginState: LoginState.LoggedIn,
        user: user,
      };

    case AuthActionType.UserHasVoted:
      const newUser: User = {
        ...state.user!,
        hasVotedIn: [...state.user!.hasVotedIn, action.payload.votingId!],
      };

      localStorage.setItem('user', JSON.stringify(newUser));

      return {
        ...state,
        user: newUser,
      };
  }
  return state;
};
