import React from 'react';
import './CommitteePage.scss';
import { useTranslation } from 'react-i18next';
import Globals from 'globals.json';

export interface CommitteePageProps {
  year: number;
}

const CommitteePage: React.FC<CommitteePageProps> = ({ year }) => {
  const { t } = useTranslation();

  return (
    <>
      <img
        src={Globals.EVENT_IMAGE}
        className='committee-page-bg-image'
        alt=''
      />
      <div className='committee-page'>
        <h3 className='committee-page__title site-header3'>
          {t('AthleteOfTheYearCommittee.AthleteOfTheYearCommittee')}
        </h3>
        <p
          className='committee-page__text'
          dangerouslySetInnerHTML={{
            __html: t('AthleteOfTheYearCommittee.Text', {
              returnObjects: true,
              joinArrays: ' <br />',
              year: year,
            }),
          }}
        ></p>
      </div>
    </>
  );
};

export default CommitteePage;
