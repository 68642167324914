import React, { useContext } from 'react';
import './Navbar.scss';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'containers/app/App';
import { LoginState } from 'reducers/Auth';
import Icon from '@mdi/react';
import { mdiClose, mdiMenu, mdiChevronDown, mdiChevronRight } from '@mdi/js';
import { AnalyticsService } from 'services/Analytics';

export type NavbarItem = {
  translationResource: string;
  translatioResourceArgs?: any;
  link: string;
  subLinks?: NavbarItem[];
};

interface NavbarProps {
  links: NavbarItem[];
  onLoginClick: () => void;
  analyticsService: AnalyticsService;
  showLogin: boolean;
}

const Navbar: React.FC<RouteComponentProps & NavbarProps> = (props) => {
  const { t } = useTranslation();
  const [toggled, setToggled] = React.useState<boolean>(false);
  const [activeSublist, setActiveSubList] = React.useState<number>(-1);
  const { authService, authState } = useContext(AppContext);
  const onLogoutClick = () => {
    authService!.logout();
  };

  React.useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }

    props.analyticsService.setCurrentPage(window.location.pathname);
    props.analyticsService.addPageView(window.location.pathname);
  }, [props.location, props.analyticsService]);

  const hideToggle = () => {
    setToggled(false);
    setActiveSubList(-1);
    document.body.style.overflow = 'auto';
  };

  const switchToggleState = () => {
    if (toggled) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }

    if (toggled) {
      setActiveSubList(-1);
    }

    setToggled(!toggled);
  };

  const switchOpenedSubList = (newIndex: number) => {
    if (activeSublist === newIndex) {
      setActiveSubList(-1);
    } else {
      setActiveSubList(newIndex);
    }
  };

  let tabIndex = 2;

  return (
    <>
      <div className='navbar__bg'></div>
      <div className={toggled ? 'navbar navbar--toggled' : 'navbar'}>
        {toggled && (
          <div className='navbar__layout' onClick={() => hideToggle()}></div>
        )}
        {!toggled && (
          <img
            onClick={() => props.history.push(`/`)}
            src='/images/logo.svg'
            className='navbar__logo'
            alt={t('General.AthleteOfTheYear')}
          />
        )}
        <div
          className={
            toggled ? 'navbar__list navbar__list--toggled' : 'navbar__list'
          }
        >
          <div className='navbar__list__item-container'>
            <img
              onClick={() => props.history.push(`/`)}
              src='/images/logo.svg'
              className='navbar__list__item-container__logo'
              alt={t('General.AthleteOfTheYear')}
            />
            {props.links.map((link, linkIndex) => (
              <div
                data-testid='navigation-link'
                key={linkIndex}
                className={
                  (props.location && props.location.pathname === link.link
                    ? 'navbar__list__item-container__item--active '
                    : '') +
                  (activeSublist === linkIndex
                    ? 'navbar__list__item-container__item--open '
                    : '') +
                  'navbar__list__item-container__item'
                }
              >
                {link.subLinks ? (
                  <>
                    <div
                      className='navbar__list__item-container__item__overlay'
                      onClick={() => hideToggle()}
                    ></div>
                    <a
                      href='# '
                      onClick={(e) => {
                        e.preventDefault();
                        switchOpenedSubList(linkIndex);
                      }}
                      role='button'
                      tabIndex={tabIndex++}
                    >
                      {t(link.translationResource, link.translatioResourceArgs)}
                      <Icon
                        path={
                          activeSublist === linkIndex
                            ? mdiChevronDown
                            : mdiChevronRight
                        }
                        size={'24px'}
                        color={'#fff'}
                        className='navbar__list__item-container__item__sublink-icon'
                      />
                    </a>
                    <div
                      className={`navbar__list__item-container__item__sublinks${
                        activeSublist === linkIndex
                          ? ' navbar__list__item-container__item__sublinks--active'
                          : ''
                      }`}
                    >
                      {link.subLinks.map((subLink, subLinkIndex) => (
                        <Link
                          to={subLink.link}
                          onClick={() => hideToggle()}
                          role='button'
                          key={subLinkIndex}
                          className={
                            props.location &&
                            props.location.pathname === subLink.link
                              ? 'navbar__list__item-container__item__sublinks__link navbar__list__item-container__item__sublinks__link--active'
                              : 'navbar__list__item-container__item__sublinks__link'
                          }
                          tabIndex={tabIndex++}
                        >
                          {t(
                            subLink.translationResource,
                            link.translatioResourceArgs
                          )}
                        </Link>
                      ))}
                    </div>
                  </>
                ) : (
                  <Link
                    to={link.link}
                    onClick={() => hideToggle()}
                    role='button'
                    tabIndex={tabIndex++}
                  >
                    {t(link.translationResource, link.translatioResourceArgs)}
                  </Link>
                )}
              </div>
            ))}
          </div>
          <div className='navbar__list__login-container'>
            {authState!.loginState === LoginState.LoggedIn ? (
              <a
                href='#/'
                className='navbar__list__login-container__logout-button'
                onClick={(e) => {
                  e.preventDefault();
                  hideToggle();
                  onLogoutClick();
                }}
              >
                <p>{t('Auth.Logout')}</p>
              </a>
            ) : props.showLogin ? (
              <a
                href='#/'
                className='navbar__list__login-container__login-button'
                onClick={(e) => {
                  e.preventDefault();
                  hideToggle();
                  props.onLoginClick();
                }}
              >
                <p>{t('General.GoToVoting')}</p>
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
        <a
          className='navbar__toggle-button'
          onClick={(e) => {
            e.preventDefault();
            switchToggleState();
          }}
          role='button'
          href='#/'
          tabIndex={toggled ? 1 : -1}
          aria-label={toggled ? t('General.Close') : t('General.OpenMenu')}
        >
          <Icon
            path={toggled ? mdiClose : mdiMenu}
            size={'48px'}
            color='#fff'
          />
        </a>
      </div>
    </>
  );
};

export default withRouter(Navbar);
