import './ResetPasswordPage.scss';
import React, { useContext } from 'react';
import '../modals/LoginModal/LoginModal.scss';
import { Translation } from 'react-i18next';
import IconInput from 'components/IconInput';
import { mdiAccountCircle } from '@mdi/js';
import { isEmail } from 'utils/validation';
import { AppContext } from 'containers/app/App';
import { ForgotPasswordState } from 'reducers/Auth';

const ResetPasswordPage: React.FC = () => {
  const [forgotPasswordEmail, setForgotPasswordEmail] =
    React.useState<string>('');

  const { authService, authState } = useContext(AppContext);

  const onForgotPasswordSendClick = () => {
    authService!.requestForgotPassword(forgotPasswordEmail);
    setForgotPasswordEmail('');
  };

  // Sets focus on emailInput field (so user can immediately write there without clicking the field)
  const emailInput = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (emailInput && emailInput.current) {
      emailInput.current.focus();
    }
  }, []);

  return (
    <div className='resetPasswordPage'>
      <Translation>
        {(t) => (
          <div className='login-modal__form'>
            <h3 className='site-header3'>{t('Login.ResetPasswordTitle')}</h3>
            <p className='login-modal__form__instructions'>
              {t('Login.ForgotPasswordInstructions')}
            </p>
            <IconInput
              type='email'
              className='login-modal__form__input'
              placeholder={t('General.Email')}
              value={forgotPasswordEmail}
              disabled={false}
              iconPath={mdiAccountCircle}
              onChange={(value: string) => setForgotPasswordEmail(value)}
              reference={emailInput}
              ariaLabel={t('General.Email')}
            />
            <div className='login-modal__form__buttons'>
              <button
                className='login-modal__form__buttons__submit'
                onClick={onForgotPasswordSendClick}
                disabled={
                  authState!.forgotPasswordState ===
                    ForgotPasswordState.Requesting ||
                  !isEmail(forgotPasswordEmail)
                }
              >
                {t('General.Send')}
              </button>
            </div>
          </div>
        )}
      </Translation>
    </div>
  );
};

export default ResetPasswordPage;
