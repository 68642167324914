import React, { useContext, useEffect, useState } from 'react';
import './Frontpage.scss';
import { useTranslation } from 'react-i18next';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'containers/app/App';
import BorderTitle from 'components/BorderTitle';
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  Dot,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { getLatestVoting } from 'utils/voting';
import { VotingStatus } from 'models/Voting/VotingStatus';
import Globals from 'globals.json';
import { News } from 'models/News';
import {
  getCandidateFrontpageImagePathWithS3Url,
  updateNewsImagePath,
} from 'utils/url';
import { formatDateToDateTimeString } from 'utils/time';

const MAX_AMOUNT_OF_NEWS_IN_CAROUSEL = 5;

// used in window resize handler to make sure the event doesn't fire too often (can now fire only once in 0.1s)
const debounce = (fn: Function, ms = 100) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

const Frontpage: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const { votingService, votingState, commonState, analyticsService } =
    useContext(AppContext);
  const latestVoting = getLatestVoting(votingState!.votings);
  const latestVotingStatus = latestVoting
    ? latestVoting.getStatus()
    : VotingStatus.UNKNOWN;

  const latestNews = commonState!.news.filter(
    (_, newsItemIndex: number) => newsItemIndex < MAX_AMOUNT_OF_NEWS_IN_CAROUSEL
  );

  const hasBeenLoaded = latestVoting ? latestVoting.resultsLoaded : false;
  const votingId = latestVoting ? latestVoting.id : 0;

  useEffect(() => {
    if (
      !hasBeenLoaded &&
      votingId > 0 &&
      latestVotingStatus === VotingStatus.RESULTS_READY
    ) {
      votingService!.getResults([votingId]);
      votingService!.getCandidates(votingId);
    }
  }, [votingService, hasBeenLoaded, votingId, latestVotingStatus]);

  // eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState<number>(0);

  // Set event listener for windowWidth, used in responsive news images in conjunction with updateNewsImagePath function
  useEffect(() => {
    // Handler to call on window resize
    const debouncedResizeHandler = debounce(() => {
      // Set window width
      setWindowWidth(window.innerWidth);
    }, 100);
    // Add event listener
    window.addEventListener('resize', debouncedResizeHandler);
    // Call handler right away so state gets updated with initial window size
    debouncedResizeHandler();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <>
      <div className='frontpage'>
        <div className='frontpage__carousel'>
          {latestNews.length > 0 && (
            <CarouselProvider
              naturalSlideWidth={600}
              naturalSlideHeight={125}
              totalSlides={
                commonState!.news.length > MAX_AMOUNT_OF_NEWS_IN_CAROUSEL
                  ? MAX_AMOUNT_OF_NEWS_IN_CAROUSEL
                  : commonState!.news.length
              }
              isPlaying
              hasMasterSpinner
              interval={30000}
              dragEnabled={false}
              lockOnWindowScroll={true}
            >
              <Slider>
                {latestNews.map((newsItem: News, newsItemIndex: number) => (
                  <Slide
                    className='frontpage__carousel__item'
                    key={newsItemIndex}
                    index={newsItemIndex}
                  >
                    {newsItem.image && (
                      <Image
                        src={updateNewsImagePath(newsItem.image)}
                        className='frontpage__carousel__item__image'
                        hasMasterSpinner
                      />
                    )}
                    <div className='frontpage__carousel__item__image-bottom-fade' />
                    <div className='frontpage__carousel__item__container'>
                      <h3 className='frontpage__carousel__item__container__title site-header3'>
                        {newsItem.title}
                      </h3>
                      <p
                        className='frontpage__carousel__item__container__text text-from-newsEditor'
                        dangerouslySetInnerHTML={{ __html: newsItem.text }}
                        aria-disabled={true}
                      ></p>
                      <button
                        className='frontpage__carousel__item__container__read-more-button'
                        onClick={() =>
                          props.history.push(`/uutiset/${newsItem.path}`)
                        }
                      >
                        {t('General.ReadMore')}
                      </button>
                      <div className='frontpage__carousel__item__container__layout'></div>
                    </div>
                  </Slide>
                ))}
              </Slider>
              <div className='frontpage__carousel__dotgroup'>
                {latestNews.map((newsItem: News, newsItemIndex: number) => (
                  <Dot
                    slide={newsItemIndex}
                    key={newsItemIndex}
                    children=''
                    aria-label={t('General.ShowNews', {
                      newsTitle: newsItem.title,
                    })}
                  />
                ))}
              </div>
            </CarouselProvider>
          )}
        </div>
        <div className='frontpage__category-container'>
          {(latestVotingStatus === VotingStatus.ONGOING ||
            latestVotingStatus === VotingStatus.ENDED) && (
            <>
              <BorderTitle
                siteTitle={true}
                className='frontpage__category-container__title'
                text={t('Frontpage.CategoryTitle', {
                  year: latestVoting!.year,
                })}
              />
              <div className='frontpage__category-container__items-container'>
                <Link
                  to='/vuoden-urheilija'
                  className='frontpage__category-container__items-container__item'
                >
                  <img
                    src={Globals.ATHLETE_CATEGORY_LOGO}
                    alt={t(Globals.ATHLETE_CATEGORY_NAME)}
                    className='frontpage__category-container__items-container__item__logo'
                  />
                </Link>
                <Link
                  to='/vuoden-joukkue'
                  className='frontpage__category-container__items-container__item'
                >
                  <img
                    src={Globals.TEAM_CATEGORY_LOGO}
                    alt={t(Globals.TEAM_CATEGORY_NAME)}
                    className='frontpage__category-container__items-container__item__logo'
                  />
                </Link>
                <Link
                  to='/vuoden-valmentaja'
                  className='frontpage__category-container__items-container__item'
                >
                  <img
                    src={Globals.COACH_CATEGORY_LOGO}
                    alt={t(Globals.COACH_CATEGORY_NAME)}
                    className='frontpage__category-container__items-container__item__logo'
                  />
                </Link>
                <Link
                  to='/vuoden-nuori-urheilija'
                  className='frontpage__category-container__items-container__item'
                >
                  <img
                    src={Globals.YOUNG_ATHLETE_CATEGORY_LOGO}
                    alt={t(Globals.YOUNG_ATHLETE_CATEGORY_NAME)}
                    className='frontpage__category-container__items-container__item__logo'
                  />
                </Link>
              </div>
            </>
          )}
          {latestVotingStatus === VotingStatus.RESULTS_READY && (
            <>
              <BorderTitle
                siteTitle={true}
                className='frontpage__category-container__title-result'
                text={t('Frontpage.RersultsTitle', {
                  year: latestVoting!.year,
                })}
              />
              <div className='frontpage__category-container__items-container'>
                {latestVoting!.result && (
                  <div className='frontpage__category-container__items-container-results'>
                    {latestVoting!.result.athletes[0] && (
                      <Link
                        to='/tulokset'
                        className='frontpage__category-container__items-container__result'
                      >
                        <span
                          className='frontpage__category-container__items-container__result--image'
                          style={{
                            backgroundImage:
                              'url("' +
                              getCandidateFrontpageImagePathWithS3Url(
                                latestVoting!.result.athletes[0].imageUrl
                              ) +
                              '")',
                          }}
                        >
                          <img
                            src={Globals.ATHLETE_CATEGORY_LOGO}
                            alt={t(Globals.ATHLETE_CATEGORY_NAME)}
                            className='frontpage__category-container__items-container__item__logo'
                          />
                        </span>
                        <span className='frontpage__category-container__items-container__result--text'>
                          {latestVoting!.result.athletes[0].firstName}
                          <br />
                          {latestVoting!.result.athletes[0].lastName}
                          <span className='frontpage__category-container__items-container__result--sport'>
                            {latestVoting!.result.athletes[0].sport}
                          </span>
                        </span>
                      </Link>
                    )}
                    {latestVoting!.result.teams[0] && (
                      <Link
                        to='/tulokset'
                        className='frontpage__category-container__items-container__result'
                      >
                        <span
                          className='frontpage__category-container__items-container__result--image'
                          style={{
                            backgroundImage:
                              'url("' +
                              getCandidateFrontpageImagePathWithS3Url(
                                latestVoting!.result.teams[0].imageUrl
                              ) +
                              '")',
                          }}
                        >
                          <img
                            src={Globals.TEAM_CATEGORY_LOGO}
                            alt={t(Globals.TEAM_CATEGORY_NAME)}
                            className='frontpage__category-container__items-container__item__logo'
                          />
                        </span>
                        <span className='frontpage__category-container__items-container__result--text'>
                          {latestVoting!.result.teams[0].team}
                          <br />
                          <span className='frontpage__category-container__items-container__result--sport'>
                            {latestVoting!.result.teams[0].sport}
                          </span>
                        </span>
                      </Link>
                    )}
                    {latestVoting!.result.coaches[0] && (
                      <Link
                        to='/tulokset'
                        className='frontpage__category-container__items-container__result'
                      >
                        <span
                          className='frontpage__category-container__items-container__result--image'
                          style={{
                            backgroundImage:
                              'url("' +
                              getCandidateFrontpageImagePathWithS3Url(
                                latestVoting!.result.coaches[0].imageUrl
                              ) +
                              '")',
                          }}
                        >
                          <img
                            src={Globals.COACH_CATEGORY_LOGO}
                            alt={t(Globals.COACH_CATEGORY_NAME)}
                            className='frontpage__category-container__items-container__item__logo'
                          />
                        </span>
                        <span className='frontpage__category-container__items-container__result--text'>
                          {latestVoting!.result.coaches[0].firstName}
                          <br />
                          {latestVoting!.result.coaches[0].lastName}
                          <span className='frontpage__category-container__items-container__result--sport'>
                            {latestVoting!.result.coaches[0].sport}
                          </span>
                        </span>
                      </Link>
                    )}
                    {latestVoting!.result.young[0] && (
                      <Link
                        to='/tulokset'
                        className='frontpage__category-container__items-container__result'
                      >
                        <span
                          className='frontpage__category-container__items-container__result--image'
                          style={{
                            backgroundImage:
                              'url("' +
                              getCandidateFrontpageImagePathWithS3Url(
                                latestVoting!.result.young[0].imageUrl
                              ) +
                              '")',
                          }}
                        >
                          <img
                            src={Globals.YOUNG_ATHLETE_CATEGORY_LOGO}
                            alt={t(Globals.YOUNG_ATHLETE_CATEGORY_NAME)}
                            className='frontpage__category-container__items-container__item__logo'
                          />
                        </span>
                        <span className='frontpage__category-container__items-container__result--text'>
                          {latestVoting!.result.young[0].firstName}
                          <br />
                          {latestVoting!.result.young[0].lastName}
                          <span className='frontpage__category-container__items-container__result--sport'>
                            {latestVoting!.result.young[0].sport}
                          </span>
                        </span>
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          {latestVotingStatus === VotingStatus.COMING_SOON && (
            <>
              <BorderTitle
                siteTitle={true}
                className='frontpage__category-container__title'
                text={t('Frontpage.ComingSoonTitle', {
                  year: latestVoting!.year,
                })}
              />

              <div className='frontpage__category-container__items-container frontpage__category-container__items-container--narrow'>
                <div className='frontpage__category-container__items-container__item'>
                  <img
                    src={Globals.ATHLETE_CATEGORY_LOGO}
                    alt={t(Globals.ATHLETE_CATEGORY_NAME)}
                    className='frontpage__category-container__items-container__item__logo-small'
                  />
                </div>
                <div className='frontpage__category-container__items-container__item'>
                  <img
                    src={Globals.TEAM_CATEGORY_LOGO}
                    alt={t(Globals.TEAM_CATEGORY_NAME)}
                    className='frontpage__category-container__items-container__item__logo-small'
                  />
                </div>
                <div className='frontpage__category-container__items-container__item'>
                  <img
                    src={Globals.COACH_CATEGORY_LOGO}
                    alt={t(Globals.COACH_CATEGORY_NAME)}
                    className='frontpage__category-container__items-container__item__logo-small'
                  />
                </div>
                <div className='frontpage__category-container__items-container__item'>
                  <img
                    src={Globals.YOUNG_ATHLETE_CATEGORY_LOGO}
                    alt={t(Globals.YOUNG_ATHLETE_CATEGORY_NAME)}
                    className='frontpage__category-container__items-container__item__logo-small'
                  />
                </div>
              </div>
              <h3 className='frontpage__category-container__candidates-revealed-text site-header3'>
                {t('Frontpage.CandidatesRevealed', {
                  day: latestVoting!.startTime.getDate(),
                  month: latestVoting!.startTime.getMonth() + 1,
                  year: latestVoting!.startTime.getFullYear(),
                })}
              </h3>
            </>
          )}
          <a
            href='https://www.codemate.com'
            target='_blank'
            rel='noopener noreferrer'
            aria-label={t('Collaboration.Codemate')}
            onClick={() => {
              analyticsService!.addEvent(
                'LinkOpened',
                'Codemate frontpage voting'
              );
            }}
          >
            <img
              src='images/made_with_love_codemate.png'
              alt=''
              className={
                'frontpage__category-container__made-by-codemate-image' +
                (latestVotingStatus === VotingStatus.COMING_SOON ||
                latestVotingStatus === VotingStatus.RESULTS_READY
                  ? ' frontpage__category-container__made-by-codemate-image--smaller'
                  : '')
              }
            />
          </a>
        </div>
        <div className='frontpage__introduction-container'>
          <img
            src={Globals.EVENT_IMAGE}
            className='frontpage__introduction-container__image'
            alt=''
          />
          <BorderTitle
            className='frontpage__introduction-container__title'
            text={t('Frontpage.IntroductionTitle')}
          />
          <p
            className='frontpage__introduction-container__text'
            dangerouslySetInnerHTML={{
              __html: t('Frontpage.IntroductionText', {
                returnObjects: true,
                joinArrays: ' <br />',
              }),
            }}
          ></p>
          <p className='frontpage__introduction-container__brand'>
            <a
              href='http://www.urheilutoimittajat.fi'
              target='_blank'
              rel='noopener noreferrer'
              aria-label={t('General.UrheilutoimittajainLiitto')}
              className='frontpage__introduction-container__brand__link'
            >
              <img
                src='images/urheilutoimittajain_liitto_logo.png'
                alt={t('General.UrheilutoimittajainLiitto')}
                className='frontpage__introduction-container__brand__link__image'
              />
            </a>
          </p>
          <BorderTitle
            className='frontpage__introduction-container__collaboration-title'
            text={t('Frontpage.InCollaborationWith')}
          />
          <div className='frontpage__introduction-container__collaboration-container'>
            <div className='frontpage__introduction-container__collaboration_block'>
              <a
                href='https://urheilugaala.fi'
                target='_blank'
                rel='noopener noreferrer'
                aria-label={t('Collaboration.Urheilugaala')}
                className='frontpage__introduction-container__collaboration-container__link'
                onClick={() => {
                  analyticsService!.addEvent(
                    'LinkOpened',
                    'Urheilugaala frontpage collaboration'
                  );
                }}
              >
                <img
                  src='images/collaboration/urheilugaala_logo.png'
                  alt={t('Collaboration.Urheilugaala')}
                  className='frontpage__introduction-container__collaboration-container__link__image'
                />
              </a>
              <a
                href='https://www.codemate.com'
                target='_blank'
                rel='noopener noreferrer'
                aria-label={t('Collaboration.Codemate')}
                className='frontpage__introduction-container__collaboration-container__link'
                onClick={() => {
                  analyticsService!.addEvent(
                    'LinkOpened',
                    'Codemate frontpage collaboration'
                  );
                }}
              >
                <img
                  src='images/collaboration/codemate.png'
                  alt={t('Collaboration.Codemate')}
                  className='frontpage__introduction-container__collaboration-container__link__image'
                />
              </a>
              <a
                href='https://haaga-helia.fi'
                target='_blank'
                rel='noopener noreferrer'
                aria-label={t('Collaboration.HaagaHelia')}
                className='frontpage__introduction-container__collaboration-container__link'
                onClick={() => {
                  analyticsService!.addEvent(
                    'LinkOpened',
                    'HaagaHelia Line frontpage collaboration'
                  );
                }}
              >
                <img
                  src='images/collaboration/haaga_helia_logo.png'
                  alt={t('Collaboration.HaagaHelia')}
                  className='frontpage__introduction-container__collaboration-container__link__image'
                />
              </a>
            </div>
            <div className='frontpage__introduction-container__collaboration_banner'>
              <a
                href='https://haaga-helia.fi'
                target='_blank'
                rel='noopener noreferrer'
                aria-label={t('Collaboration.HaagaHelia')}
                className='frontpage__introduction-container__collaboration-container__link'
                onClick={() => {
                  analyticsService!.addEvent(
                    'LinkOpened',
                    'HaagaHelia frontpage collaboration'
                  );
                }}
              >
                <picture>
                  <source
                    media='(max-width: 700px)'
                    srcSet='images/collaboration/haaga_helia_banner_mobile_2022.jpg'
                  />
                  <img
                    src='images/collaboration/haaga_helia_banner_2022.jpg'
                    alt={t('Collaboration.HaagaHelia')}
                    className='frontpage__introduction-container__collaboration-container__link__image'
                  />
                </picture>
              </a>
            </div>
          </div>
        </div>
        <div className='frontpage__newsarchive-container'>
          <BorderTitle
            className='frontpage__newsarchive__title'
            text={t('General.NewsArchive')}
          />
          {latestNews.map(
            (archiveNewsItem: News, archiveNewsItemIndex: number) => (
              <div
                className='frontpage__newsarchive__newsitem-container'
                key={archiveNewsItemIndex}
                onClick={() =>
                  props.history.push(`/uutiset/${archiveNewsItem.path}`)
                }
              >
                <h3
                  className='site-header3'
                  dangerouslySetInnerHTML={{
                    __html: archiveNewsItem.title,
                  }}
                />
                <h3
                  className='frontpage__newsarchive__date site-header3'
                  dangerouslySetInnerHTML={{
                    __html: formatDateToDateTimeString(
                      archiveNewsItem.created,
                      'fi'
                    ),
                  }}
                />
              </div>
            )
          )}
          <button
            className='frontpage__carousel__item__container__read-more-button'
            onClick={() => props.history.push('/uutisarkisto')}
          >
            {t('General.OlderNews')}
          </button>
        </div>
      </div>
    </>
  );
};

export default withRouter(Frontpage);
