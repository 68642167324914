import React, { useEffect } from 'react';
import './Snackbar.scss';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { useTranslation } from 'react-i18next';

export enum SnackbarType {
  Error = 'error',
  Success = 'success',
}

interface SnackbarProps {
  text: string;
  eventTime: number;
  timeout?: number;
  type: SnackbarType;
}

interface SnackbarState {
  visible: boolean;
}

let timer: any = null;

const Snackbar = React.memo((props: SnackbarProps) => {
  const { t } = useTranslation();
  const [state, setState] = React.useState<SnackbarState>({
    visible: false,
  });

  useEffect(() => {
    if (props.text.length === 0) {
      return;
    }

    setState({
      visible: true,
    });

    if (timer) {
      clearTimeout(timer);
    }

    if (props.timeout !== 0) {
      timer = setTimeout(() => {
        setState({
          visible: false,
        });
      }, props.timeout || 4000);
    }
  }, [props.text, props.eventTime, props.timeout, setState]);

  const hideSnackbar = () => {
    if (timer) {
      clearTimeout(timer);
    }

    setState({
      ...state,
      visible: false,
    });
  };

  return (
    <div
      className={
        'snackbar' +
        (props.type === SnackbarType.Error
          ? ' snackbar--error'
          : ' snackbar--success') +
        (state.visible ? ' snackbar--visible' : '')
      }
    >
      <div className='snackbar__container'>
        {state.visible && (
          <div className='snackbar__container__text' role='alert'>
            {props.text}
          </div>
        )}
        <div
          className='snackbar__container__close-button'
          onClick={hideSnackbar}
          role='button'
          aria-label={t('General.Close')}
        >
          <Icon path={mdiClose} size={'29px'} color='white' />
        </div>
      </div>
    </div>
  );
});

export default Snackbar;
