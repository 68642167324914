export type TimeLocale = 'fi';

export const formatDateToDateTimeString = (
  time: Date,
  locale: TimeLocale
): string => {
  if (!time) {
    return '';
  }

  switch (locale) {
    case 'fi':
      return `${time.getDate()}.${time.getMonth() + 1}.${time.getFullYear()} ${
        time.toTimeString().split(' ')[0]
      }`;
  }

  return '';
};
