import React, { useContext } from 'react';
import './LoginModal.scss';
import Modal from '../Modal/Modal';
import { Translation } from 'react-i18next';
import { AppContext } from 'containers/app/App';
import {
  LoginState,
  RequestRegisterState,
  ForgotPasswordState,
} from 'reducers/Auth';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import RequestRegisterForm from './RequestRegisterForm';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface LoginModalProps {
  onClose: () => void;
}

enum LoginModalView {
  Login = 'login',
  ForgotPassword = 'forgotPassword',
  RequestRegister = 'requestRegister',
}

export interface LoginModalState {
  username: string;
  password: string;
  recaptchaToken?: string;
  recaptchaRequired: boolean;
}

const LoginModal: React.FC<RouteComponentProps & LoginModalProps> = (props) => {
  const [loginValues, setLoginValues] = React.useState<LoginModalState>({
    username: '',
    password: '',
    recaptchaToken: '',
    recaptchaRequired: false,
  });
  const [forgotPasswordEmail, setForgotPasswordEmail] =
    React.useState<string>('');
  const [requestRegisterEmail, setRequestRegisterEmail] =
    React.useState<string>('');
  const [loginView, setLoginView] = React.useState<LoginModalView>(
    LoginModalView.Login
  );

  const { authService, authState } = useContext(AppContext);

  const onLoginClick = () => {
    authService!
      .login({
        email: loginValues.username,
        password: loginValues.password,
        recaptchaToken: loginValues.recaptchaToken,
      })
      .then(() => {
        setLoginValues({
          ...loginValues,
          recaptchaRequired: false,
        });

        props.history.push(`/aanestys`);
        props.onClose();
      })
      .catch((err: any) => {
        if (err && err.requireCaptcha) {
          setLoginValues({
            ...loginValues,
            recaptchaRequired: true,
          });
        }
      });
  };

  const onForgotPasswordSendClick = () => {
    authService!.requestForgotPassword(forgotPasswordEmail).then(() => {
      props.onClose();
    });
  };

  const onRequestRegisterSendClick = () => {
    authService!.requestRegister(requestRegisterEmail).then(() => {
      props.onClose();
    });
  };

  return (
    <Translation>
      {(t) => (
        <Modal onBackdropClick={props.onClose} onCloseClick={props.onClose}>
          <div className='login-modal'>
            <div className='login-modal__image'></div>
            {loginView === LoginModalView.Login ? (
              <LoginForm
                onSubmit={onLoginClick}
                setValues={setLoginValues}
                values={loginValues}
                onForgotPasswordClick={() =>
                  setLoginView(LoginModalView.ForgotPassword)
                }
                onRequestRegisterClick={() =>
                  setLoginView(LoginModalView.RequestRegister)
                }
                disabled={authState!.loginState !== LoginState.LoggedOut}
                onClose={props.onClose}
              />
            ) : loginView === LoginModalView.ForgotPassword ? (
              <ForgotPasswordForm
                disabled={
                  authState!.forgotPasswordState ===
                  ForgotPasswordState.Requesting
                }
                onViewSwitch={() => setLoginView(LoginModalView.Login)}
                forgotPasswordEmail={forgotPasswordEmail}
                setForgotPasswordEmail={setForgotPasswordEmail}
                onSubmit={onForgotPasswordSendClick}
                onClose={props.onClose}
              />
            ) : (
              <RequestRegisterForm
                disabled={
                  authState!.requestRegisterState ===
                  RequestRegisterState.Requesting
                }
                onViewSwitch={() => setLoginView(LoginModalView.Login)}
                requestRegisterEmail={requestRegisterEmail}
                setRequestRegisterEmail={setRequestRegisterEmail}
                onSubmit={onRequestRegisterSendClick}
                onClose={props.onClose}
              />
            )}
          </div>
        </Modal>
      )}
    </Translation>
  );
};

export default withRouter(LoginModal);
