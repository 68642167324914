import React, { useContext } from 'react';
import './ResultArchivePage.scss';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'containers/app/App';
import { getStringArrayAsString } from 'utils/array';
import Globals from 'globals.json';
import BorderTitle from 'components/BorderTitle';
import { Candidate } from 'models/Candidate/Candidate';
import { VotingStatus } from 'models/Voting/VotingStatus';
import ResultCandidateGrid from 'components/Result/ResultCandidateGrid';
import { getCommonImagePathWithS3Url } from 'utils/url';

export enum ResultArchivePageType {
  Athlete = 'athlete',
  Team = 'team',
  Coach = 'coach',
  Young = 'young',
}

export interface ResultArchivePageProps {
  type: ResultArchivePageType;
}

interface ResultArchive {
  logo?: string;
  nameResource: string;
  bgImage: string;
  scoresAvailable: boolean;
  categories: ResultArchiveCategory[];
}

interface ResultArchiveCategory {
  year: number;
  firstPlacesAvailable: boolean;
  candidates: Candidate[];
}

const ResultArchivePage: React.FC<ResultArchivePageProps> = (props) => {
  const { t } = useTranslation();
  const { votingService, votingState } = useContext(AppContext);
  const votings = votingState!.votings.filter(
    (voting) => voting.getStatus() === VotingStatus.RESULTS_READY
  );
  const notLoadedVotingsIds = votings
    .filter((voting) => !voting.resultsLoaded)
    .map((voting) => voting.id);

  React.useEffect(() => {
    if (notLoadedVotingsIds.length > 0) {
      votingService!.getResults(notLoadedVotingsIds);
    }
  }, [votingService, notLoadedVotingsIds]);

  if (votings.length === 0) {
    return <></>;
  }

  let result: ResultArchive | undefined = undefined;

  switch (props.type) {
    case ResultArchivePageType.Athlete:
      result = {
        categories: votings
          .filter((voting) => voting.result)
          .map((voting) => {
            return {
              year: voting.year,
              candidates: voting.result ? voting.result.athletes : [],
              firstPlacesAvailable: true,
            } as ResultArchiveCategory;
          }),
        logo: Globals.ATHLETE_CATEGORY_LOGO,
        bgImage: getCommonImagePathWithS3Url(Globals.ATHLETE_CATEGORY_BG),
        nameResource: Globals.ATHLETE_CATEGORY_NAME,
        scoresAvailable: true,
      };
      break;
    case ResultArchivePageType.Team:
      result = {
        categories: votings
          .filter((voting) => voting.result)
          .map((voting) => {
            return {
              year: voting.year,
              candidates: voting.result ? voting.result.teams : [],
              firstPlacesAvailable:
                voting.year >= Globals.FIRST_PLACES_AVAILABLE_YEAR,
            } as ResultArchiveCategory;
          }),
        logo: Globals.TEAM_CATEGORY_LOGO,
        bgImage: getCommonImagePathWithS3Url(Globals.TEAM_CATEGORY_BG),
        nameResource: Globals.TEAM_CATEGORY_NAME,
        scoresAvailable: true,
      };
      break;
    case ResultArchivePageType.Coach:
      result = {
        categories: votings
          .filter((voting) => voting.result)
          .map((voting) => {
            return {
              year: voting.year,
              candidates: voting.result ? voting.result.coaches : [],
              firstPlacesAvailable:
                voting.year >= Globals.FIRST_PLACES_AVAILABLE_YEAR,
            } as ResultArchiveCategory;
          }),
        logo: Globals.COACH_CATEGORY_LOGO,
        bgImage: getCommonImagePathWithS3Url(Globals.COACH_CATEGORY_BG),
        nameResource: Globals.COACH_CATEGORY_NAME,
        scoresAvailable: true,
      };
      break;
    case ResultArchivePageType.Young:
      result = {
        categories: votings
          .filter((voting) => voting.result)
          .map((voting) => {
            return {
              year: voting.year,
              candidates: voting.result ? voting.result.young : [],
              firstPlacesAvailable:
                voting.year >= Globals.FIRST_PLACES_AVAILABLE_YEAR,
            } as ResultArchiveCategory;
          }),
        logo: Globals.YOUNG_ATHLETE_CATEGORY_LOGO,
        nameResource: Globals.YOUNG_ATHLETE_CATEGORY_NAME,
        bgImage: getCommonImagePathWithS3Url(Globals.YOUNG_ATHLETE_CATEGORY_BG),
        scoresAvailable: true,
      };
      break;
  }

  if (!result) {
    return <></>;
  }

  return (
    <div className='results-archive'>
      <img src={result!.bgImage} className='results-archive__bg-image' alt='' />
      <div className='results-archive__container'>
        {result!.categories
          .filter((_, categoryIndex) => categoryIndex === 0)
          .map((category, categoryIndex) => (
            <div
              className='results-archive__container__latest'
              key={categoryIndex}
            >
              <BorderTitle
                className='results-archive__container__latest__title'
                text={`${t('Results.Results')} ${category.year}`}
              />
              <ResultCandidateGrid
                key={categoryIndex}
                candidates={category.candidates}
                logo={result!.logo}
                nameResource={result!.nameResource}
                showScores={result!.scoresAvailable}
                showFirstPlaces={category.firstPlacesAvailable}
              />
            </div>
          ))}
        {result.categories.length > 1 && (
          <>
            <BorderTitle
              className='results-archive__container__older-results-title'
              text={t('Results.OlderResults')}
            />

            <div className='results-archive__container__older-results'>
              {result!.categories
                .filter(
                  (category, categoryIndex) =>
                    categoryIndex > 0 && category.candidates.length > 0
                )
                .map((category, categoryIndex) => (
                  <div
                    className='results-archive__container__older-results-archive__item'
                    key={categoryIndex}
                  >
                    <p className='results-archive__container__older-results-archive__item__year'>
                      {category.year}
                    </p>

                    <p className='results-archive__container__older-results-archive__item__name'>
                      {getStringArrayAsString(
                        category.candidates[0].getNames()
                      )}
                    </p>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ResultArchivePage;
