import React from 'react';
import './BorderTitle.scss';

export interface BorderTitleProps {
  text: string;
  className: string;
  siteTitle?: Boolean;
}

const BorderTitle: React.FC<BorderTitleProps> = (props) => {
  let element;

  if (props.siteTitle) {
    element = (
      <h1 className='border-title__title site-header1'>
        {props.text}
        <div className='border-title__title__border' />
      </h1>
    );
  } else {
    element = (
      <h2 className='border-title__title site-header2'>
        {props.text}
        <div className='border-title__title__border' />
      </h2>
    );
  }

  return (
    <div
      className={
        'border-title' +
        (props.className.length > 0 ? ` ${props.className}` : '')
      }
    >
      {element}
    </div>
  );
};

export default BorderTitle;
