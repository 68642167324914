import React from 'react';
import './ConfirmModal.scss';
import Modal from '../Modal/Modal';
import { Translation } from 'react-i18next';

interface ConfirmModalProps {
  onConfirm: () => void;
  onClose: () => void;
  textResource: string;
  secondaryTextResource?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  onConfirm,
  onClose,
  textResource,
  secondaryTextResource,
}) => {
  return (
    <Translation>
      {(t) => (
        <Modal onBackdropClick={onClose} onCloseClick={onClose}>
          <div className='confirm-modal'>
            <p className='confirm-modal__text'>{t(textResource)}</p>
            {secondaryTextResource && (
              <p className='confirm-modal__secondary-text'>
                {t(secondaryTextResource)}
              </p>
            )}
            <div className='confirm-modal__button-container'>
              <button
                className='confirm-modal__button-container__cancel-button'
                onClick={onClose}
              >
                {t('General.Close')}
              </button>
              <button
                className='confirm-modal__button-container__confirm-button'
                onClick={onConfirm}
              >
                {t('General.Confirm')}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Translation>
  );
};

export default ConfirmModal;
