import './NewsArchivePage.scss';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import BorderTitle from 'components/BorderTitle';
import { AppContext } from 'containers/app/App';
import { News } from 'models/News';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { formatDateToDateTimeString } from 'utils/time';
import Globals from 'globals.json';

// Creates newsarchive, populates it from commonState. Sets elements for rendering the archive in the App file
// The page's title is taken from translate file named fi.json
// Newsitem's text is shortened and all the HTML tags are removed from it so mask-image css style in applied correctly
const NewsArchivePage: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const { commonState } = useContext(AppContext);
  const allNews = commonState!.news;
  return (
    <div className='newsarchive'>
      <img src={Globals.EVENT_IMAGE} className='newsarchive__bg-image' alt='' />
      <BorderTitle
        className='newsarchive__title'
        text={t('General.NewsArchive')}
      />
      {allNews.map((newsItem: News, newsItemIndex: number) => (
        <div
          className='newsarchive__newsitem-container'
          key={newsItemIndex}
          onClick={() => props.history.push(`/uutiset/${newsItem.path}`)}
        >
          <h3
            className='site-header3'
            dangerouslySetInnerHTML={{
              __html: newsItem.title,
            }}
          />
          <h3
            className='newsarchive__newsitem__date site-header3'
            dangerouslySetInnerHTML={{
              __html: formatDateToDateTimeString(newsItem.created, 'fi'),
            }}
          />
          <div className='newsarchive__newsitem__text-container'>
            <p
              className='newsarchive__newsitem__text-container__text'
              dangerouslySetInnerHTML={{
                __html: newsItem.text
                  .replace(/(?!<p>)(?!\\r)(<([^>]+)>)/gi, '')
                  .slice(0, 500),
              }}
            ></p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default withRouter(NewsArchivePage);
