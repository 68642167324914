import React from 'react';
import './LoginModal.scss';
import { Translation } from 'react-i18next';
import IconInput from 'components/IconInput';
import { mdiAccountCircle } from '@mdi/js';
import { isEmail } from 'utils/validation';

interface RequestRegisterFormProps {
  onSubmit: () => void;
  requestRegisterEmail: string;
  setRequestRegisterEmail: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
  onViewSwitch: () => void;
  onClose: () => void;
}

const RequestRegisterForm: React.FC<RequestRegisterFormProps> = (props) => {
  const emailInput = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (emailInput && emailInput.current) {
      emailInput.current.focus();
    }
  }, []);

  return (
    <Translation>
      {(t) => (
        <div className='login-modal__form'>
          <h3 className='site-header3'>{t('Login.RequestRegisterTitle')}</h3>
          <p className='login-modal__form__instructions'>
            {t('Login.RequestRegisterInstructions')}
          </p>
          <IconInput
            type='email'
            className='login-modal__form__input'
            placeholder={t('General.Email')}
            value={props.requestRegisterEmail}
            disabled={props.disabled}
            iconPath={mdiAccountCircle}
            onChange={(value: string) => props.setRequestRegisterEmail(value)}
            reference={emailInput}
            onSubmit={() => {
              if (props.disabled || !isEmail(props.requestRegisterEmail)) {
                return false;
              }

              props.onSubmit();
            }}
            aria-label={t('General.Email')}
          />
          <div className='login-modal__form__switch-link-container'>
            <span className='login-modal__form__instructions'>
              {t('Login.AlreadyHaveAccount')}
            </span>
            <a
              href='#/'
              onClick={(e) => {
                e.preventDefault();
                props.onViewSwitch();
              }}
              aria-label={t('Auth.LoginLong')}
            >
              {t('Auth.LoginLong')}
            </a>
          </div>
          <div className='login-modal__form__buttons'>
            <button
              className='login-modal__form__buttons__close'
              onClick={props.onClose}
            >
              {t('General.Close')}
            </button>
            <button
              className='login-modal__form__buttons__submit'
              onClick={props.onSubmit}
              disabled={props.disabled || !isEmail(props.requestRegisterEmail)}
            >
              {t('General.Send')}
            </button>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default RequestRegisterForm;
