import ReactGA from 'react-ga';

export type AnalyticsEventCategory = 'LinkOpened';

export class AnalyticsService {
  isInitialized: boolean;

  constructor() {
    this.isInitialized = false;
  }

  initialize(trackingID: string): void {
    ReactGA.initialize(trackingID);
    this.isInitialized = true;
  }

  setCurrentPage(path: string): void {
    if (this.isInitialized) {
      ReactGA.set({ page: path });
    }
  }

  addPageView(path: string): void {
    if (this.isInitialized) {
      ReactGA.pageview(path);
    }
  }

  addEvent(category: AnalyticsEventCategory, action: string): void {
    if (this.isInitialized) {
      ReactGA.event({
        category: category,
        action: action,
      });
    }
  }
}
