import React, { useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'containers/app/App';
import IconInput from 'components/IconInput';
import { mdiLockOpen } from '@mdi/js';
import { parseQuery } from 'utils/url';
import Globals from 'globals.json';
import CommonForm from 'components/CommonForm';
import { isEmail } from 'utils/validation';

interface ResetPasswordState {
  newPassword: string;
  newPasswordConfirm: string;
  email: string;
  token: string;
  resettingPassword: boolean;
}

interface QueryParam {
  email: string;
  token: string;
}

const ResetPassword: React.FC<
  RouteComponentProps & {
    onResetPasswordSuccess: () => void;
  }
> = (props) => {
  const { t } = useTranslation();
  const { authService } = useContext(AppContext);
  const [state, setState] = React.useState<ResetPasswordState>({
    newPassword: '',
    newPasswordConfirm: '',
    email: '',
    token: '',
    resettingPassword: false,
  });

  const passwordInput = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (passwordInput && passwordInput.current) {
      passwordInput.current.focus();
    }
  }, []);

  React.useEffect(() => {
    try {
      //ewoiZW1haWwiOiAidm90ZXJAY29kZW1hdGUuY29tIiwKInRva2VuIjogInRoaXNpc2F0b2tlbiIKfQ%3D%3D
      //https://www.browserling.com/tools/json-to-base64
      //https://www.browserling.com/tools/url-encode

      const queryParams = parseQuery(decodeURIComponent(props.location.search));
      const tokenQueryParam = queryParams.find((param) => param[0] === 't');
      if (tokenQueryParam && tokenQueryParam.length >= 2) {
        const tokenQueryParamValues: QueryParam = JSON.parse(
          window.atob(tokenQueryParam[1])
        );

        setState({
          newPassword: '',
          newPasswordConfirm: '',
          resettingPassword: false,
          email: tokenQueryParamValues.email,
          token: tokenQueryParamValues.token,
        });
      }
    } catch (e) {}
  }, [setState, props.location.search]);

  const onSubmitClick = () => {
    setState({
      ...state,
      resettingPassword: true,
    });

    authService!
      .resetPassword({
        email: state.email,
        accessToken: state.token,
        password: state.newPassword,
        confirmPassword: state.newPasswordConfirm,
      })
      .then(() => {
        setState({
          ...state,
          resettingPassword: false,
        });

        props.history.replace('/');
        props.onResetPasswordSuccess();
      })
      .catch(() => {
        setState({
          ...state,
          resettingPassword: false,
        });
      });
  };

  const isValid =
    state.newPassword.length >= Globals.PASSWORD_MIN_LENGTH &&
    state.newPasswordConfirm.length >= Globals.PASSWORD_MIN_LENGTH &&
    state.newPassword === state.newPasswordConfirm;

  return (
    <CommonForm>
      <h3 className='common-form__form-container__form__title site-header3'>
        {t('General.Welcome')}!
      </h3>
      <p className='common-form__form-container__form__instructions'>
        {t('ResetPassword.Instructions', {
          email: state.email,
          passwordMinLength: Globals.PASSWORD_MIN_LENGTH,
        })}
      </p>
      <IconInput
        type='password'
        className='common-form__form-container__form__input'
        placeholder={t('Auth.Password')}
        value={state.newPassword}
        iconPath={mdiLockOpen}
        disabled={state.resettingPassword}
        onChange={(value: string) =>
          setState({
            ...state,
            newPassword: value,
          })
        }
        reference={passwordInput}
        autoComplete='new-password'
        ariaLabel={t('Auth.Password')}
      />
      <IconInput
        type='password'
        className='common-form__form-container__form__input'
        placeholder={t('Auth.PasswordConfirm')}
        value={state.newPasswordConfirm}
        iconPath={mdiLockOpen}
        disabled={state.resettingPassword}
        onChange={(value: string) =>
          setState({
            ...state,
            newPasswordConfirm: value,
          })
        }
        onSubmit={() => {
          if (!isValid || state.resettingPassword || !isEmail(state.email)) {
            return false;
          }

          onSubmitClick();
        }}
        autoComplete='new-password'
        ariaLabel={t('Auth.PasswordConfirm')}
      />
      <button
        className='common-form__form-container__form__action-button'
        onClick={() => isValid && onSubmitClick()}
        disabled={!isValid || state.resettingPassword || !isEmail(state.email)}
      >
        {t('General.Send')}
      </button>
    </CommonForm>
  );
};

export default withRouter(ResetPassword);
