import { AuthAction, AuthActionType } from 'reducers/Auth';
import { User } from 'models/User';
import axios from 'axios';
import Globals from 'globals.json';
import { CommonAction, CommonActionType } from 'reducers/Common';
import { SnackbarType } from 'components/Snackbar/Snackbar';
import { isInvalidEmailResponse } from 'utils/validation';

export interface LoginParams {
  email: string;
  password: string;
  recaptchaToken?: string;
}

export interface LoginResponse {
  email: string;
  firstName: string;
  lastName: string;
}

export interface RegisterParams {
  email: string;
  password: string;
  confirmPassword: string;
  accessToken: string;
  club: number;
  timeOfBirth: number;
  address: string;
  postOffice: string;
  postalCode: string;
  phoneNumber: string;
}

export interface ResetPasswordParams {
  accessToken: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export class AuthService {
  authDispatch!: React.Dispatch<AuthAction>;
  commonDispatch!: React.Dispatch<CommonAction>;

  constructor(
    authDispatch: React.Dispatch<AuthAction>,
    commonDispatch: React.Dispatch<CommonAction>
  ) {
    this.authDispatch = authDispatch;
    this.commonDispatch = commonDispatch;
  }

  login(params: LoginParams): Promise<LoginResponse | void> {
    return new Promise((resolve, reject) => {
      this.authDispatch!({
        type: AuthActionType.LoggingIn,
        payload: {},
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/login`, params, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
          timeout: Globals.TIMEOUT,
        })
        .then((response) => {
          const user: User = {
            email: response.data.email,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            hasVotedIn: response.data.hasVotedIn.map((value: any) =>
              Number(value)
            ),
          };

          this.authDispatch!({
            type: AuthActionType.Login,
            payload: {
              user: user,
            },
          });

          this.commonDispatch!({
            type: CommonActionType.ShowSnackbar,
            payload: {
              snackbarMessage: 'Login.LoginSuccess',
              snackbarType: SnackbarType.Success,
            },
          });

          resolve();
        })
        .catch((err) => {
          this.authDispatch!({
            type: AuthActionType.Logout,
            payload: {},
          });

          if (err.response && err.response.status === 401) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.LoginFailed',
              },
            });
          } else if (err.response && err.response.status === 403) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: `Error.${err.response.data.messageId}`,
              },
            });

            if (
              err.response.data.messageId === 'captchaChallengeRequired' ||
              err.response.data.messageId === 'captchaChallengeFailed'
            ) {
              reject({
                requireCaptcha: true,
              });
            }
          } else if (isInvalidEmailResponse(err.response)) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.InvalidEmail',
              },
            });
          } else {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.UnknownErrorTryAgainLater',
              },
            });
          }

          reject();
        });
    });
  }

  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/logout`, undefined, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
          timeout: Globals.TIMEOUT,
        })
        .then(() => {
          this.authDispatch!({
            type: AuthActionType.Logout,
            payload: {
              redirectToHome: true,
            },
          });

          resolve();
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.LogoutFailed',
              },
            });
          } else if (err.response && err.response.status === 401) {
            // Session ended
            this.authDispatch!({
              type: AuthActionType.Logout,
              payload: {
                redirectToHome: true,
              },
            });
          } else {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.UnknownErrorTryAgainLater',
              },
            });
          }

          reject(err);
        });
    });
  }

  register(params: RegisterParams): Promise<void> {
    return new Promise((resolve, reject) => {
      this.authDispatch!({
        type: AuthActionType.Registering,
        payload: {},
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/register`, params, {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: Globals.TIMEOUT,
        })
        .then(() => {
          this.authDispatch!({
            type: AuthActionType.RegisterSucceed,
            payload: {},
          });

          this.commonDispatch!({
            type: CommonActionType.ShowSnackbar,
            payload: {
              snackbarMessage: 'Register.Success',
              snackbarType: SnackbarType.Success,
            },
          });

          resolve();
        })
        .catch((err) => {
          this.authDispatch!({
            type: AuthActionType.RegisterFailed,
            payload: {
              registerFailedMessage: err,
            },
          });

          if (err.response && err.response.status === 400) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.FormValuesWereInvalid',
              },
            });
          } else if (isInvalidEmailResponse(err.response)) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.InvalidEmail',
              },
            });
          } else {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.UnknownErrorTryAgainLater',
              },
            });
          }

          reject(err);
        });
    });
  }

  requestForgotPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.authDispatch!({
        type: AuthActionType.ForgotPassword,
        payload: {},
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/auth/request-password-reset`,
          {
            email: email,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: Globals.TIMEOUT,
          }
        )
        .then(() => {
          this.authDispatch!({
            type: AuthActionType.ForgotPasswordSucceed,
            payload: {},
          });

          this.commonDispatch!({
            type: CommonActionType.ShowSnackbar,
            payload: {
              snackbarMessage: 'Login.ForgotPasswordSuccess',
              snackbarType: SnackbarType.Success,
              snackbarMessageArgs: {
                email: email,
              },
            },
          });

          resolve();
        })
        .catch((err) => {
          this.authDispatch!({
            type: AuthActionType.ForgotPasswordFailed,
            payload: {},
          });

          if (isInvalidEmailResponse(err.response)) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.InvalidEmail',
              },
            });
          } else {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.UnknownErrorTryAgainLater',
              },
            });
          }

          reject(err);
        });
    });
  }

  resetPassword(params: ResetPasswordParams): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, params, {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: Globals.TIMEOUT,
        })
        .then(() => {
          resolve();

          this.commonDispatch!({
            type: CommonActionType.ShowSnackbar,
            payload: {
              snackbarMessage: 'ResetPassword.Success',
              snackbarType: SnackbarType.Success,
            },
          });
        })
        .catch((err) => {
          if (isInvalidEmailResponse(err.response)) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.InvalidEmail',
              },
            });
          } else if (err.response && err.response.status === 400) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.ResetPasswordTokenHasExpired',
              },
            });
          } else {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.UnknownErrorTryAgainLater',
              },
            });
          }

          reject(err);
        });
    });
  }

  requestRegister(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.authDispatch!({
        type: AuthActionType.RequestingRegister,
        payload: {},
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/auth/request-registration-email`,
          {
            email: email,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: Globals.TIMEOUT,
          }
        )
        .then(() => {
          this.authDispatch!({
            type: AuthActionType.RequestingRegisterSucceed,
            payload: {},
          });

          this.commonDispatch!({
            type: CommonActionType.ShowSnackbar,
            payload: {
              snackbarMessage: 'Login.RequestRegisterSuccess',
              snackbarType: SnackbarType.Success,
              snackbarMessageArgs: {
                email: email,
              },
            },
          });

          resolve();
        })
        .catch((err) => {
          this.authDispatch!({
            type: AuthActionType.RequestingRegisterFailed,
            payload: {},
          });

          if (isInvalidEmailResponse(err.response)) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.InvalidEmail',
              },
            });
          } else if (err.response && err.response.status === 403) {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.noSuchUser',
              },
            });
          } else {
            this.commonDispatch!({
              type: CommonActionType.ShowSnackbar,
              payload: {
                snackbarMessage: 'Error.UnknownErrorTryAgainLater',
              },
            });
          }
          reject(err);
        });
    });
  }

  validateSession(): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/auth/validate-session`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
          timeout: Globals.TIMEOUT,
        })
        .then(() => {
          this.authDispatch!({
            type: AuthActionType.SessionValidated,
            payload: {},
          });

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
