import React from 'react';
import './LoginModal.scss';
import { Translation } from 'react-i18next';
import IconInput from 'components/IconInput';
import { mdiLockOpen, mdiAccountCircle } from '@mdi/js';
import { LoginModalState } from './LoginModal';
import { isEmail } from 'utils/validation';
import ReCAPTCHA from 'react-google-recaptcha';

interface LoginFormProps {
  onSubmit: () => void;
  values: LoginModalState;
  setValues: React.Dispatch<React.SetStateAction<LoginModalState>>;
  disabled: boolean;
  onForgotPasswordClick: () => void;
  onRequestRegisterClick: () => void;
  onClose: () => void;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const isLoginValid =
    isEmail(props.values.username) &&
    props.values.password.length > 0 &&
    (!props.values.recaptchaRequired ||
      (props.values.recaptchaRequired &&
        props.values.recaptchaToken &&
        props.values.recaptchaToken.length > 0));

  const emailInput = React.useRef<HTMLInputElement>(null);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  React.useEffect(() => {
    if (emailInput && emailInput.current) {
      emailInput.current.focus();
    }
  }, []);

  const onRecaptchaChange = (token: string | null) => {
    props.setValues({
      ...props.values,
      recaptchaToken: token || undefined, //Can be null if recaptcha token has expired (user has to check again to get a new one)
    });
  };

  return (
    <Translation>
      {(t) => (
        <div className='login-modal__form'>
          <h3 className='site-header3'>{t('General.Welcome')}!</h3>
          <IconInput
            type='email'
            className='login-modal__form__input'
            placeholder={t('General.Email')}
            value={props.values.username}
            iconPath={mdiAccountCircle}
            disabled={props.disabled}
            onChange={(value: string) =>
              props.setValues({
                ...props.values,
                username: value,
              })
            }
            onSubmit={() => isLoginValid && props.onSubmit()}
            reference={emailInput}
            ariaLabel={t('General.Email')}
          />
          <IconInput
            className='login-modal__form__input'
            type='password'
            placeholder={t('Auth.Password')}
            value={props.values.password}
            iconPath={mdiLockOpen}
            disabled={props.disabled}
            onChange={(value: string) =>
              props.setValues({
                ...props.values,
                password: value,
              })
            }
            onSubmit={() => isLoginValid && props.onSubmit()}
            ariaLabel={t('Auth.Password')}
          />
          <div className='login-modal__form__switch-link-container'>
            <span className='login-modal__form__instructions'>
              {t('Login.NoAccountInstructions')}
            </span>
            <a
              href='#/'
              onClick={(e) => {
                e.preventDefault();
                props.onRequestRegisterClick();
              }}
              aria-label={t('Login.OpenLoginRequestRegisterInstructions')}
            >
              {t('Login.LoginRequestRegisterInstructions')}
            </a>
          </div>
          <div className='login-modal__form__switch-link-container'>
            <span className='login-modal__form__instructions'>
              {t('Login.LoginForgotPasswordInstructions')}
            </span>
            <a
              href='#/'
              onClick={(e) => {
                e.preventDefault();
                props.onForgotPasswordClick();
              }}
              aria-label={t('Auth.OpenResetPasswordView')}
            >
              {t('Auth.ChangePassword')}
            </a>
          </div>
          <p className='login-modal__form__help-instructions'>
            {t('Login.HelpInstructions')}
          </p>
          {props.values.recaptchaRequired && (
            <div className='login-modal__form_recaptcha-container'>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                onChange={onRecaptchaChange}
                theme='dark'
                hl='fi'
                ref={recaptchaRef}
              />
            </div>
          )}
          <div className='login-modal__form__buttons'>
            <button
              className='login-modal__form__buttons__close'
              onClick={props.onClose}
            >
              {t('General.Close')}
            </button>
            <button
              className='login-modal__form__buttons__submit'
              onClick={() => {
                if (recaptchaRef && recaptchaRef.current) {
                  recaptchaRef.current.reset();
                }

                props.onSubmit();
              }}
              disabled={props.disabled || !isLoginValid}
            >
              {t('Auth.Login')}
            </button>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default LoginForm;
