import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/reset.scss';
import './index.scss';
import './i18n';
import App from './containers/app';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root') as Element;

const root = createRoot(rootElement);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
