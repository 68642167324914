import { VotingValues } from 'models/Voting/VotingValues';
import { VotingCategoryCandidates } from 'models/Voting/VotingCategory';
import { VotingStep } from 'models/Voting/VotingStep';
import { Voting } from 'models/Voting/Voting';
import { VotingResult } from 'models/Voting/VotingResult';
import Globals from 'globals.json';
import { sortVotingsByYearDesc } from 'utils/sort';

export enum VoteState {
  NotVoted,
  SendingVotes,
  HasVoted,
}

export interface VotingState {
  votings: Voting[];
  votingStep: VotingStep;
  voteState: VoteState;
  stepCategories: string[];
}

export enum VotingActionType {
  UpdateVotings = 'updateVotings',
  InitVotingCategoryValues = 'initVotingCategoryValues',
  UpdateVotingCategoryValues = 'updateVotingCategoryValues',
  SendingVotes = 'sendingVotes',
  SendingVotesSucceed = 'sendingVotesSucceed',
  SendingVotesFailed = 'sendingVotesFailed',
  VotingStepChange = 'votingStepChange',
  UpdateResultValuesForVoting = 'updateResultValuesForVoting',
}

export interface VotingAction {
  type: VotingActionType;
  payload: {
    votingId?: number;
    votings?: Voting[];
    initVotingValues?: VotingValues;
    categoryCandidates?: VotingCategoryCandidates;
    votingStep?: VotingStep;
    votingResults?: { [id: number]: VotingResult | undefined };
  };
}

export const VotingInitState = (): VotingState => {
  return {
    votings: [],
    votingStep: VotingStep.Athlete,
    voteState: VoteState.NotVoted,
    stepCategories: [
      Globals.ATHLETE_CATEGORY_NAME,
      Globals.TEAM_CATEGORY_NAME,
      Globals.COACH_CATEGORY_NAME,
      Globals.YOUNG_ATHLETE_CATEGORY_NAME,
      'General.Summary',
    ],
  };
};

export const VotingReducer = (state: VotingState, action: VotingAction) => {
  switch (action.type) {
    case VotingActionType.UpdateVotings:
      const sortedFilteredVotings = sortVotingsByYearDesc(
        action.payload.votings!
      ).filter((voting) => voting.isValid());

      return {
        ...state,
        votings: sortedFilteredVotings,
      };
    case VotingActionType.InitVotingCategoryValues:
      return {
        ...state,
        votings: state.votings.map((voting: Voting) => {
          if (voting.id === action.payload.votingId) {
            voting.setCandidates(
              action.payload.initVotingValues!.athletes,
              action.payload.initVotingValues!.teams,
              action.payload.initVotingValues!.coaches,
              action.payload.initVotingValues!.young
            );
          }

          return voting;
        }),
      };
    case VotingActionType.UpdateResultValuesForVoting:
      const resultDataKeys = action.payload.votingResults
        ? Object.keys(action.payload.votingResults)
        : [];
      return {
        ...state,
        votings: state.votings.map((voting: Voting) => {
          const result = action.payload.votingResults![voting.id];

          // If we have tried to load results for this voting,
          // then we will set the result will it be undefined or not.
          // Otherwise there will be possibility for infinite loop.
          if (resultDataKeys.indexOf(voting.id.toString()) >= 0) {
            voting.setResults(result);
          }

          return voting;
        }),
      };
    case VotingActionType.UpdateVotingCategoryValues:
      return {
        ...state,
        votings: state.votings.map((voting: Voting) => {
          if (voting.id === action.payload.votingId) {
            switch (state.votingStep) {
              case VotingStep.Athlete:
                voting.updateAthleteCategoryCandidates(
                  action.payload.categoryCandidates!.candidates,
                  action.payload.categoryCandidates!.selectedCandidates
                );
                break;
              case VotingStep.Team:
                voting.updateTeamCategoryCandidates(
                  action.payload.categoryCandidates!.candidates,
                  action.payload.categoryCandidates!.selectedCandidates
                );
                break;
              case VotingStep.Coach:
                voting.updateCoachCategoryCandidates(
                  action.payload.categoryCandidates!.candidates,
                  action.payload.categoryCandidates!.selectedCandidates
                );
                break;
              case VotingStep.Young:
                voting.updateYoungCategoryCandidates(
                  action.payload.categoryCandidates!.candidates,
                  action.payload.categoryCandidates!.selectedCandidates
                );
                break;
            }
          }

          return voting;
        }),
      };
    case VotingActionType.SendingVotesSucceed:
      return {
        ...state,
        voteState: VoteState.HasVoted,
      };
    case VotingActionType.SendingVotes:
      return {
        ...state,
        voteState: VoteState.SendingVotes,
      };
    case VotingActionType.SendingVotesFailed:
      return {
        ...state,
        voteState: VoteState.NotVoted,
      };
    case VotingActionType.VotingStepChange:
      return {
        ...state,
        votingStep: action.payload.votingStep!,
      };
  }
  return state;
};
