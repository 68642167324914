import React, { PropsWithChildren } from 'react';
import './CommonForm.scss';
import Globals from 'globals.json';

const CommonForm: React.FC<PropsWithChildren> = (props) => {
  return (
    <div className='common-form'>
      <img
        src={Globals.EVENT_IMAGE}
        className='common-form__bg_image_upper'
        alt=''
      />
      <div className='common-form__form-container'>
        <div className='common-form__form-container__image' />
        <div className='common-form__form-container__form'>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default CommonForm;
