import React from 'react';
import './IconDatepicker.scss';
import Icon from '@mdi/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi';
import { mdiAlertCircle } from '@mdi/js';
registerLocale('fi', fi);

//CHANGE THIS IF YOU ADD MORE LANGUAGES

// https://materialdesignicons.com/

export interface IconDatepickerProps {
  onChange: (value: Date | null) => void;
  value: Date | null;
  iconPath: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  showTimeInput?: boolean;
  dateFormat?: string;
  isClearable?: boolean;
  onSubmit?: () => void;
  reference?: any;
  maxDate?: Date;
  minDate?: Date;
  showYearDropdown?: boolean;
  yearItemCount?: number;
  showValidState?: boolean;
  isValid?: boolean;
  iconSize?: string;
}

const IconDatepicker: React.FC<IconDatepickerProps> = (props) => {
  return (
    <div className={`${props.className || ''} icon-datepicker-container`}>
      <Icon
        path={props.iconPath}
        size={props.iconSize || '24px'}
        color={props.showValidState && !props.isValid ? '#e95617' : '#ddb54c'}
        className='icon-datepicker-container__icon'
      />
      <DatePicker
        selected={props.value}
        onChange={(date) => props.onChange(date)}
        disabled={props.disabled || false}
        className={
          'icon-datepicker-container__datepicker' +
          (props.showValidState && props.isValid
            ? ' icon-datepicker-container__datepicker--valid'
            : props.showValidState && !props.isValid
            ? ' icon-datepicker-container__datepicker--invalid'
            : '')
        }
        placeholderText={props.placeholder || ''}
        showTimeInput={props.showTimeInput || false}
        dateFormat={props.dateFormat}
        isClearable={props.isClearable || true}
        onKeyDown={(e) => {
          if (props.onSubmit && e.keyCode === 13) {
            // Enter
            props.onSubmit();
          }
        }}
        ref={props.reference}
        locale={fi}
        showYearDropdown={props.showYearDropdown || false}
        yearDropdownItemNumber={props.yearItemCount}
        scrollableYearDropdown={true}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
      {props.showValidState && !props.isValid && (
        <Icon
          path={mdiAlertCircle}
          size={props.iconSize || '24px'}
          color='#e95617'
          className='icon-input-container__alert-icon'
        />
      )}
    </div>
  );
};

export default IconDatepicker;
