import React from 'react';
import './UsageTermsPage.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Globals from 'globals.json';

const UsageTermsPage: React.FC = () => {
  const { t } = useTranslation();
  const privacyPolicySections: string[] = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
  ];

  const cookiePolicySections: string[] = ['1', '2', '3', '3-1', '3-2', '4'];

  // Called when clicked hashLinkClick on this page.
  const hashLinkClick = (hashLinkID: string) => {
    const element = document.getElementById(hashLinkID);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { hash, key } = useLocation();

  // Called when arriving to this page from another page (when useLocation's hash or key values change)
  React.useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [hash, key]);

  return (
    <>
      <img src={Globals.EVENT_IMAGE} className='usage-terms-bg-image' alt='' />
      <div className='usage-terms'>
        <div className='usage-terms__link-container'>
          <a
            className='usage-terms__link-container__link'
            href='#tietosuoja'
            onClick={(e) => {
              e.preventDefault();
              hashLinkClick('tietosuoja');
            }}
          >
            {t('UsageTerms.PrivacyPolicy')}
          </a>
          <a
            className='usage-terms__link-container__link'
            href='#yleiset-kayttoehdot'
            onClick={(e) => {
              e.preventDefault();
              hashLinkClick('yleiset-kayttoehdot');
            }}
          >
            {t('UsageTerms.UsageTerms')}
          </a>
          <a
            className='usage-terms__link-container__link'
            href='#evaste-kaytanto'
            onClick={(e) => {
              e.preventDefault();
              hashLinkClick('evaste-kaytanto');
            }}
          >
            {t('UsageTerms.CookiePolicy')}
          </a>
        </div>
        <h3 className='usage-terms__title site-header3' id='tietosuoja'>
          {t('UsageTerms.PrivacyPolicy')}
        </h3>
        {privacyPolicySections.map((section: string, index: number) => (
          <div className='usage-terms__section' key={index}>
            <p className='usage-terms__section__title'>
              {t(`UsageTerms.PrivacyPolicy_${section}-Title`)}
            </p>
            <p
              className='usage-terms__section__text'
              dangerouslySetInnerHTML={{
                __html: t(`UsageTerms.PrivacyPolicy_${section}-Html`),
              }}
            ></p>
          </div>
        ))}
        <h3
          className='usage-terms__title site-header3'
          id='yleiset-kayttoehdot'
        >
          {t('UsageTerms.UsageTerms')}
        </h3>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_1-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_1-1-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_1-2-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_1-3-Html`),
            }}
          ></p>
        </div>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_2-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_2-1-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_2-2-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_2-3-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_2-4-Html`),
            }}
          ></p>
        </div>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_3-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_3-Html`),
            }}
          ></p>
        </div>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_4-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_4-Html`),
            }}
          ></p>
        </div>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_5-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_5-1-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_5-2-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_5-3-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_5-4-Html`),
            }}
          ></p>
        </div>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_6-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_6-1-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_6-2-Html`),
            }}
          ></p>
        </div>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_7-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_7-Html`),
            }}
          ></p>
        </div>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_8-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_8-1-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_8-2-Html`),
            }}
          ></p>
        </div>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_9-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_9-Html`),
            }}
          ></p>
        </div>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_10-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_10-1-Html`),
            }}
          ></p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_10-2-Html`),
            }}
          ></p>
        </div>
        <div className='usage-terms__section'>
          <p className='usage-terms__section__title'>
            {t(`UsageTerms.UsageTerms_12-Title`)}
          </p>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.UsageTerms_12-Html`),
            }}
          ></p>
        </div>
        <h3 className='usage-terms__title site-header3' id='evaste-kaytanto'>
          {t('UsageTerms.CookiePolicy')}
        </h3>
        <div className='usage-terms__section'>
          <p
            className='usage-terms__section__text'
            dangerouslySetInnerHTML={{
              __html: t(`UsageTerms.CookiePolicy_Intro`),
            }}
          ></p>
        </div>
        <div id='test'></div>
        {cookiePolicySections.map((section: string, index: number) => (
          <div className='usage-terms__section' key={index}>
            <p className='usage-terms__section__title'>
              {t(`UsageTerms.CookiePolicy_${section}-Title`)}
            </p>
            <p
              className='usage-terms__section__text'
              dangerouslySetInnerHTML={{
                __html: t(`UsageTerms.CookiePolicy_${section}-Html`),
              }}
            ></p>
          </div>
        ))}
      </div>
    </>
  );
};

export default UsageTermsPage;
