import axios from 'axios';
import Globals from 'globals.json';
import { CommonAction, CommonActionType } from 'reducers/Common';
import { News } from 'models/News';
import { getNewsImagePathWithS3Url } from 'utils/url';

export class CommonService {
  commonDispatch!: React.Dispatch<CommonAction>;

  constructor(commonDispatch: React.Dispatch<CommonAction>) {
    this.commonDispatch = commonDispatch;
  }

  getClubs(): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/options/clubs`, {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: Globals.TIMEOUT,
        })
        .then((response) => {
          this.commonDispatch!({
            type: CommonActionType.UpdateClubs,
            payload: {
              clubs: response.data.map((club: any) => {
                return {
                  id: club.id,
                  name: club.name,
                };
              }),
            },
          });

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSports(): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/options/sports`, {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: Globals.TIMEOUT,
        })
        .then((response) => {
          this.commonDispatch!({
            type: CommonActionType.UpdateSports,
            payload: {
              sports: response.data,
            },
          });

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getNews(): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/news`, {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: Globals.TIMEOUT,
        })
        .then((response) => {
          this.commonDispatch!({
            type: CommonActionType.UpdateNews,
            payload: {
              news: response.data.map((news: any) => {
                return {
                  title: news.title,
                  text: news.text,
                  published: news.published,
                  image: getNewsImagePathWithS3Url(news.image),
                  path: news.path,
                  created: new Date(news.created),
                } as News;
              }),
            },
          });

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
