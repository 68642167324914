import React, { useContext } from 'react';
import './OwnCandidateModal.scss';
import Modal from '../Modal/Modal';
import { Translation } from 'react-i18next';
import IconInput from 'components/IconInput';
import { mdiAccountCircle, mdiTrophy, mdiBiathlon } from '@mdi/js';
import IconSelect from 'components/IconSelect';
import { AppContext } from 'containers/app/App';
interface OwnCandidateModalProps {
  onClose: () => void;
  onSubmit: (
    firstName: string,
    lastName: string,
    firstName2: string,
    lastName2: string,
    sport: string,
    argument: string
  ) => void;
  year: number;
}

export interface OwnCandidateModalState {
  firstName: string;
  lastName: string;
  firstName2: string;
  lastName2: string;
  sport: string;
  argument: string;
  single: boolean;
}

const OwnCandidateModal: React.FC<OwnCandidateModalProps> = (props) => {
  const [values, setValues] = React.useState<OwnCandidateModalState>({
    firstName: '',
    lastName: '',
    firstName2: '',
    lastName2: '',
    sport: '',
    argument: '',
    single: true,
  });

  const { commonState } = useContext(AppContext);
  const firstNameInput = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (firstNameInput && firstNameInput.current) {
      firstNameInput.current.focus();
    }
  }, []);

  const isValid =
    values.firstName.length > 0 &&
    values.lastName.length > 0 &&
    values.sport.length > 0 &&
    values.argument.length > 0 &&
    (values.single || values.firstName2.length > 0) &&
    (values.single || values.lastName2.length > 0);

  const submitValues = () => {
    props.onSubmit(
      values.firstName,
      values.lastName,
      values.single ? '' : values.firstName2,
      values.single ? '' : values.lastName2,
      values.sport,
      values.argument
    );
  };

  return (
    <Translation>
      {(t) => (
        <Modal onBackdropClick={props.onClose} onCloseClick={props.onClose}>
          <div className='own-candidate-modal'>
            <div className='own-candidate-modal__image'></div>

            <div className='own-candidate-modal__form'>
              <h3 className='site-header3'>{t('General.OwnCandidate')}</h3>
              <div className='own-candidate-modal__form__state-container'>
                <label>
                  <input
                    type='radio'
                    defaultChecked={values.single}
                    name='state'
                    onChange={() => {
                      setValues({
                        ...values,
                        single: true,
                      });
                    }}
                  />
                  <span>{t('OwnCandidateModal.Single')}</span>
                </label>
                <label>
                  <input
                    type='radio'
                    defaultChecked={!values.single}
                    name='state'
                    onChange={() => {
                      setValues({
                        ...values,
                        single: false,
                      });
                    }}
                  />
                  <span>{t('OwnCandidateModal.Pair')}</span>
                </label>
              </div>
              <IconInput
                className='own-candidate-modal__form__input'
                placeholder={
                  values.single
                    ? t('General.Firstname')
                    : t('OwnCandidateModal.AthleteFirstName', {
                        index: 1,
                      })
                }
                value={values.firstName}
                iconPath={mdiAccountCircle}
                onChange={(value: string) =>
                  setValues({
                    ...values,
                    firstName: value,
                  })
                }
                onSubmit={() => isValid && submitValues()}
                reference={firstNameInput}
                ariaLabel={
                  values.single
                    ? t('General.Firstname')
                    : t('OwnCandidateModal.AthleteFirstName', {
                        index: 1,
                      })
                }
              />
              <IconInput
                className='own-candidate-modal__form__input'
                placeholder={
                  values.single
                    ? t('General.Lastname')
                    : t('OwnCandidateModal.AthleteLastName', {
                        index: 1,
                      })
                }
                value={values.lastName}
                iconPath={mdiAccountCircle}
                onChange={(value: string) =>
                  setValues({
                    ...values,
                    lastName: value,
                  })
                }
                onSubmit={() => isValid && submitValues()}
                ariaLabel={
                  values.single
                    ? t('General.Lastname')
                    : t('OwnCandidateModal.AthleteLastName', { index: 1 })
                }
              />
              {!values.single && (
                <>
                  <IconInput
                    className='own-candidate-modal__form__input'
                    placeholder={t('OwnCandidateModal.AthleteFirstName', {
                      index: 2,
                    })}
                    value={values.firstName2}
                    iconPath={mdiAccountCircle}
                    onChange={(value: string) =>
                      setValues({
                        ...values,
                        firstName2: value,
                      })
                    }
                    onSubmit={() => isValid && submitValues()}
                    ariaLabel={t('OwnCandidateModal.AthleteFirstName', {
                      index: 2,
                    })}
                  />
                  <IconInput
                    className='own-candidate-modal__form__input'
                    placeholder={t('OwnCandidateModal.AthleteLastName', {
                      index: 2,
                    })}
                    value={values.lastName2}
                    iconPath={mdiAccountCircle}
                    onChange={(value: string) =>
                      setValues({
                        ...values,
                        lastName2: value,
                      })
                    }
                    onSubmit={() => isValid && submitValues()}
                    ariaLabel={t('OwnCandidateModal.AthleteLastName', {
                      index: 2,
                    })}
                  />
                </>
              )}
              <IconSelect
                className='own-candidate-modal__form__input'
                placeholderText={t('General.Sport')}
                value={values.sport}
                iconPath={mdiBiathlon}
                onChange={(value: string) =>
                  setValues({
                    ...values,
                    sport: value,
                  })
                }
                options={commonState!.sports.map((sport) => {
                  return {
                    value: sport,
                    text: sport,
                  };
                })}
                ariaLabel={t('General.Sport')}
              />
              <IconInput
                className='own-candidate-modal__form__input'
                placeholder={t('General.Arguments', { year: props.year })}
                value={values.argument}
                iconPath={mdiTrophy}
                onChange={(value: string) =>
                  setValues({
                    ...values,
                    argument: value,
                  })
                }
                ariaLabel={t('General.Arguments')}
              />
              <p className='own-candidate-modal__form__instructions'>
                {t('General.ArgumentsInfo', { year: props.year })}
              </p>
              <div className='own-candidate-modal__form__buttons'>
                <button
                  className='own-candidate-modal__form__buttons__close'
                  onClick={props.onClose}
                >
                  {t('General.Close')}
                </button>
                <button
                  className='own-candidate-modal__form__buttons__submit'
                  onClick={submitValues}
                  disabled={!isValid}
                >
                  {t('General.Add')}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Translation>
  );
};

export default OwnCandidateModal;
