import React, { useContext } from 'react';
import './CandidatePage.scss';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'containers/app/App';
import { VotingCategory } from 'models/Voting/VotingCategory';
import {
  getStringArrayAsJsxElements,
  getStringArrayAsString,
} from 'utils/array';
import BorderTitle from 'components/BorderTitle';
import { sortByCandidatesBySortKeyAsc } from 'utils/sort';
import { mdiTrophy } from '@mdi/js';
import Icon from '@mdi/react';
import LazyLoad from 'react-lazyload';
import { getCandidateGalleryImagePathWithS3Url } from 'utils/url';

export interface CandidateProps {
  category: VotingCategory;
  votingId: number;
  votingYear: number;
  hasBeenLoaded: boolean;
  introductionTextResource: string;
  includeOwnCandidate?: boolean;
}

const CandidatePage: React.FC<RouteComponentProps & CandidateProps> = (
  props
) => {
  const { t } = useTranslation();
  const { votingService } = useContext(AppContext);

  React.useEffect(() => {
    if (!props.hasBeenLoaded) {
      votingService!.getCandidates(props.votingId);
    }
  }, [votingService, props.votingId, props.hasBeenLoaded]);

  let candidates = sortByCandidatesBySortKeyAsc(
    [...props.category.candidates, ...props.category.selectedCandidates].filter(
      (candidate) => candidate.id > 0 //Filter out own candidates
    )
  );

  if (props.includeOwnCandidate)
    candidates.push({
      id: 0,
      firstName: t('CandidatePage.OwnCandidate.firstName'),
      lastName: t('CandidatePage.OwnCandidate.lastName'),
      imageUrl: 'oma-valinta.jpg',
      sport: t('CandidatePage.OwnCandidate.sport'),
      achievements: [
        t('CandidatePage.OwnCandidate.achievements01'),
        t('CandidatePage.OwnCandidate.achievements02'),
      ],
      isOwnCandidate: () => false,
      getSortKey: () => '',
      getNames: () => [t('CandidatePage.OwnCandidate.getNames')],
    });

  return (
    <div className='candidate'>
      <img
        src={props.category.bgImageSrc}
        className='candidate__bg-image'
        alt=''
      />
      <div className='candidate__container'>
        <BorderTitle
          className='candidate__container__title'
          text={t(props.category.nameResource)}
        />
        <p
          className='candidate__container__introduction'
          dangerouslySetInnerHTML={{
            __html: t(props.introductionTextResource),
          }}
        ></p>
        <BorderTitle
          className='candidate__container__candidates'
          text={t('General.CandidatesYear', { year: props.votingYear })}
        />
        <br />
        <br />
        <div className='candidate__container__grid'>
          {candidates.map((candidate) => {
            return (
              <div
                key={candidate.id}
                className='candidate__container__grid__item'
              >
                <LazyLoad height={470} once>
                  <img
                    src={getCandidateGalleryImagePathWithS3Url(
                      candidate.imageUrl
                    )}
                    className='candidate__container__grid__item__image'
                    alt={getStringArrayAsString(candidate.getNames())}
                  />
                </LazyLoad>

                <div className='candidate__container__grid__item__info-layout'></div>
                <div className='candidate__container__grid__item__info-container'>
                  <div className='candidate__container__grid__item__info-container__list-container'>
                    <div className='candidate__container__grid__item__info-container__list-container__name'>
                      {getStringArrayAsJsxElements(candidate.getNames())}
                    </div>
                    <p className='candidate__container__grid__item__info-container__list-container__sport'>
                      {candidate.sport}
                    </p>
                    <div className='candidate__container__grid__item__info-container__list-container__achievements'>
                      {candidate.achievements.map(
                        (achievement: string, index: number) => (
                          <div
                            key={index}
                            className='candidate__container__grid__item__info-container__list-container__achievements__item'
                          >
                            <Icon
                              path={mdiTrophy}
                              size={'20px'}
                              color='#ddb54c'
                              className='candidate__container__grid__item__info-container__list-container__achievements__item__icon'
                            />
                            <p className='candidate__container__grid__item__info-container__list-container__achievements__item__text'>
                              {achievement}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <p
            className='candidate__container__credits'
            dangerouslySetInnerHTML={{
              __html: t('CandidatePage.Credits'),
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CandidatePage);
