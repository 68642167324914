import React from 'react';
import { useTranslation } from 'react-i18next';
import { VotingCategory } from 'models/Voting/VotingCategory';
import './VotingSummaryView.scss';
import { getStringArrayAsString } from 'utils/array';
import { getCandidateVotingImagePathWithS3Url } from 'utils/url';

export interface VotingSummaryViewProps {
  votingCategories: VotingCategory[];
  points: {
    [key: number]: Object[];
  };
}

const VotingSummaryView: React.FC<VotingSummaryViewProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='voting-summary'>
        {props.votingCategories.map((votingCategory, votingCategoryIndex) => (
          <div
            key={votingCategoryIndex}
            className='voting-summary__list-container'
          >
            <h3 className='voting-summary__list-container__title site-header3'>
              {t(votingCategory.nameResource)}
            </h3>
            <ul className='voting-summary__list-container__list'>
              {votingCategory.selectedCandidates.map(
                (selection, selectionIndex) => (
                  <li
                    className='voting-summary__list-container__list__item'
                    key={selectionIndex}
                  >
                    <div className='voting-summary__list-container__list-item__order-text'>
                      <div>
                        <p className='voting-summary__list-container__list-item__order-text-number'>
                          #{selectionIndex + 1}
                        </p>
                        <p className='voting-summary__list-container__list-item__order-text-position'>
                          <>
                            {
                              props.points[votingCategoryIndex + 1][
                                selectionIndex
                              ]
                            }{' '}
                            p.
                          </>
                        </p>
                      </div>{' '}
                    </div>
                    <div className='voting-summary__list-container__list-item__container'>
                      <img
                        className='voting-summary__list-container__list-item__container__image'
                        src={
                          selection.isOwnCandidate()
                            ? selection.imageUrl
                            : getCandidateVotingImagePathWithS3Url(
                                selection.imageUrl
                              )
                        }
                        alt={getStringArrayAsString(selection.getNames())}
                      />
                      <div className='voting-summary__list-container__list-item__container__text-container'>
                        <div className='voting-summary__list-container__list-item__container__text-container__name'>
                          {getStringArrayAsString(selection.getNames())}
                        </div>
                        <div className='voting-summary__list-container__list-item__container__text-container__info'>
                          <p>{selection.sport}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        ))}
      </div>
      <div>
        <p
          className='voting-summary-info'
          dangerouslySetInnerHTML={{
            __html: t('Voting.SummaryInfo'),
          }}
        ></p>
      </div>
    </div>
  );
};

export default VotingSummaryView;
