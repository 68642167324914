import React from 'react';
import './VotingInstructionsModal.scss';
import Modal from '../Modal/Modal';
import { Translation } from 'react-i18next';

interface VotingInstructionsModalProps {
  onClose: () => void;
  year: number;
}

const VotingInstructionsModal: React.FC<VotingInstructionsModalProps> = ({
  onClose,
  year,
}) => {
  return (
    <Translation>
      {(t) => (
        <Modal onBackdropClick={onClose} onCloseClick={onClose}>
          <div className='instructions-modal'>
            <h3 className='instructions-modal__title site-header3'>
              {t('Voting.VotingInstrctions')}
            </h3>
            <div className='instructions-modal__content-container'>
              <p className='instructions-modal__content-container__title'>
                {t('VotingInstructions.AthleteOfTheYearTitle')}
              </p>
              <p
                className='instructions-modal__content-container__text'
                dangerouslySetInnerHTML={{
                  __html: t('VotingInstructions.AthleteOfTheYearInstructions', {
                    returnObjects: true,
                    joinArrays: ' <br />',
                    year: year,
                  }),
                }}
              ></p>
              <p className='instructions-modal__content-container__title'>
                {t('VotingInstructions.TeamOfTheYearTitle')}
              </p>
              <p
                className='instructions-modal__content-container__text'
                dangerouslySetInnerHTML={{
                  __html: t('VotingInstructions.TeamOfTheYearInstructions', {
                    returnObjects: true,
                    joinArrays: ' <br />',
                    year: year,
                  }),
                }}
              ></p>
              <p className='instructions-modal__content-container__title'>
                {t('VotingInstructions.CoachOfTheYearTitle')}
              </p>
              <p
                className='instructions-modal__content-container__text'
                dangerouslySetInnerHTML={{
                  __html: t('VotingInstructions.CoachOfTheYearInstructions', {
                    returnObjects: true,
                    joinArrays: ' <br />',
                    year: year,
                  }),
                }}
              ></p>
              <p className='instructions-modal__content-container__title'>
                {t('VotingInstructions.YoungAthleteOfTheYearTitle')}
              </p>
              <p
                className='instructions-modal__content-container__text'
                dangerouslySetInnerHTML={{
                  __html: t(
                    'VotingInstructions.YoungAthleteOfTheYearInstructions',
                    {
                      returnObjects: true,
                      joinArrays: ' <br />',
                      year: year,
                    }
                  ),
                }}
              ></p>
            </div>
            <button
              className='instructions-modal__close-button'
              onClick={onClose}
            >
              {t('General.Close')}
            </button>
          </div>
        </Modal>
      )}
    </Translation>
  );
};

export default VotingInstructionsModal;
