import React, { useLayoutEffect, PropsWithChildren } from 'react';
import './Modal.scss';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  onBackdropClick?: () => void;
  onCloseClick?: () => void;
}

interface ScrollHideElement {
  element: HTMLElement;
  overflowValue: string | null;
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = (props) => {
  const { t } = useTranslation();
  useLayoutEffect(() => {
    const scrollWidth =
      window.innerWidth - document.documentElement.clientWidth;

    const elements: ScrollHideElement[] = [
      document.body,
      document.getElementsByClassName('navbar__list')[0] as HTMLElement,
    ]
      .filter((element) => element !== undefined)
      .map((element: HTMLElement) => {
        return {
          element: element,
          overflowValue: element.style.overflow,
        };
      });

    elements.forEach((element: ScrollHideElement) => {
      element.element.style.overflow = 'hidden';
      element.element.style.paddingRight = scrollWidth + 'px';
      element.element.style.boxSizing = 'content-box';
    });

    return () => {
      elements.forEach((element: ScrollHideElement) => {
        if (element.overflowValue) {
          element.element.style.overflow = element.overflowValue;
        } else {
          element.element.style.removeProperty('overflow');
        }

        element.element.style.paddingRight = '0';
      });
    };
  }, []);

  return (
    <div className='modal' role='dialog'>
      <div
        className='modal-backdrop'
        onClick={props.onBackdropClick ? props.onBackdropClick : undefined}
      ></div>
      <div className='modal-body'>
        <div
          className='modal-body__close-button'
          onClick={props.onCloseClick}
          role='button'
          aria-label={t('General.Close')}
          tabIndex={0}
        >
          <Icon path={mdiClose} size={'30px'} color='white' />
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
