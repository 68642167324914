import React from 'react';
import './IconSelect.scss';
import Icon from '@mdi/react';

// https://materialdesignicons.com/

export interface IconSelectProps {
  onChange: (value: string) => void;
  value: string;
  iconPath: string;
  options: {
    value: string;
    text: string;
  }[];
  className?: string;
  placeholderText?: string;
  disabled?: boolean;
  reference?: any;
  showValidState?: boolean;
  isValid?: boolean;
  iconSize?: string;
  ariaLabel?: string;
}

const IconSelect: React.FC<IconSelectProps> = (props) => {
  return (
    <div className={`${props.className || ''} icon-select-container`}>
      <Icon
        path={props.iconPath}
        size={props.iconSize || '24px'}
        color={props.showValidState && !props.isValid ? '#e95617' : '#ddb54c'}
        className='icon-select-container__icon'
      />
      <select
        required
        defaultValue={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        className={
          'icon-select-container__input' +
          (props.showValidState && props.isValid
            ? ' icon-select-container__input--valid'
            : props.showValidState && !props.isValid
            ? ' icon-select-container__input--invalid'
            : '')
        }
        disabled={props.disabled || false}
        ref={props.reference}
        aria-label={props.ariaLabel}
      >
        {props.placeholderText ? (
          <option value='' disabled>
            {props.placeholderText || ''}
          </option>
        ) : (
          ''
        )}
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default IconSelect;
