export const isEmail = (email: string): boolean => {
  // prettier-ignore
  //eslint-disable-next-line
  const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return regex.test(email);
};

export const isInvalidEmailResponse = (response: any): boolean => {
  if (
    !response ||
    !response.data ||
    response.data.statusCode !== 400 ||
    !response.data.validation ||
    !response.data.validation.keys
  ) {
    return false;
  }

  return response.data.validation.keys.indexOf('email') >= 0;
};
