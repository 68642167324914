import React, { useContext } from 'react';
import './Footer.scss';
import Icon from '@mdi/react';
import { mdiFacebookBox, mdiTwitterBox, mdiInstagram } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'containers/app/App';
import { Link } from 'react-router-dom';

export interface FooterProps {
  showCodemateLogo: boolean;
}

const Footer: React.FC<FooterProps> = (props) => {
  const { t } = useTranslation();
  const { analyticsService } = useContext(AppContext);

  return (
    <>
      <div className='footer'>
        <div className='footer__fade' />
        {props.showCodemateLogo && (
          <a
            href='https://www.codemate.com'
            target='_blank'
            rel='noopener noreferrer'
            aria-label={t('Collaboration.Codemate')}
            className='footer__codemate-link'
            onClick={() => {
              analyticsService!.addEvent('LinkOpened', 'Codemate footer');
            }}
          >
            <div className='footer__codemate-link__image' />
          </a>
        )}
        <div className='footer__link-container'>
          <div className='footer__link-container__item'>
            <a
              href='http://www.urheilutoimittajat.fi'
              target='_blank'
              rel='noopener noreferrer'
              className='footer__link-container__item__link'
              aria-label={t('General.NavigateToAthleteJornalistUnionPage')}
              onClick={() => {
                analyticsService!.addEvent(
                  'LinkOpened',
                  'Urheilutoimittajat own site footer'
                );
              }}
            >
              {t('General.AthleteJournalistUnion')}
            </a>
          </div>
          <div className='footer__link-container__item__icons-container'>
            <div className='footer__link-container__item__icons-container__item'>
              <a
                href='https://www.facebook.com/urheilutoimittajat'
                target='_blank'
                rel='noopener noreferrer'
                className='footer__link-container__item__icons-container__item__link'
                aria-label={t(
                  'General.NavigateToAthleteJornalistUnionFacebookPage'
                )}
                onClick={() => {
                  analyticsService!.addEvent(
                    'LinkOpened',
                    'Urheilutoimittajat Facebook footer'
                  );
                }}
              >
                <Icon
                  path={mdiFacebookBox}
                  size={'40px'}
                  color={'#fff'}
                  className='footer__link-container__item__icons-container__item__link__icon'
                />
              </a>
            </div>
            <div className='footer__link-container__item__icons-container__item'>
              <a
                href='https://twitter.com/urheilutoimitus'
                target='_blank'
                rel='noopener noreferrer'
                className='footer__link-container__item__icons-container__item__link'
                aria-label={t(
                  'General.NavigateToAthleteJornalistUnionTwitterPage'
                )}
                onClick={() => {
                  analyticsService!.addEvent(
                    'LinkOpened',
                    'Urheilutoimittajat Twitter footer'
                  );
                }}
              >
                <Icon
                  path={mdiTwitterBox}
                  size={'40px'}
                  color={'#fff'}
                  className='footer__link-container__item__icons-container__item__link__icon'
                />
              </a>
            </div>
            <div className='footer__link-container__item__icons-container__item'>
              <a
                href='https://www.instagram.com/urheilutoimittajat/'
                target='_blank'
                rel='noopener noreferrer'
                className='footer__link-container__item__icons-container__item__link'
                aria-label={t(
                  'General.NavigateToAthleteJornalistUnionInstagramPage'
                )}
                onClick={() => {
                  analyticsService!.addEvent(
                    'LinkOpened',
                    'Urheilutoimittajat Instagram footer'
                  );
                }}
              >
                <Icon
                  path={mdiInstagram}
                  size={'40px'}
                  color={'#fff'}
                  className='footer__link-container__item__icons-container__item__link__icon'
                />
              </a>
            </div>
          </div>
          <div className='footer__link-container__item'>
            <Link
              to='/kayttoehdot'
              aria-label={t('UsageTerms.ReadUsageTerms')}
              className='footer__link-container__item__link'
            >
              {t('UsageTerms.Title')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
