import React, { useContext } from 'react';
import './NewsPage.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppContext } from 'containers/app/App';
import { News } from 'models/News';
import { formatDateToDateTimeString } from 'utils/time';

const NewsPage: React.FC<RouteComponentProps> = (props) => {
  const { commonState } = useContext(AppContext);

  const news = commonState!.news.find(
    (news: News) =>
      news.path === props.location.pathname.replace('/uutiset/', '')
  );

  if (!news) {
    return <></>;
  }

  return (
    <div className='news'>
      <img src={news.image} className='news__bg-image' alt='' />
      <div className='news__bg-image-fade' />
      <div className='news__bg-layout' />
      <div className='news__container'>
        <h2 className='news__container__title site-header2'>{news.title}</h2>
        <p className='news__container__created'>
          {formatDateToDateTimeString(news.created, 'fi')}
        </p>
        <div
          className='news__container__text text-from-newsEditor'
          dangerouslySetInnerHTML={{ __html: news.text }}
        ></div>
      </div>
    </div>
  );
};

export default withRouter(NewsPage);
