import React from 'react';
import './HasVotedPage.scss';
import { useTranslation } from 'react-i18next';
import { Voting } from 'models/Voting/Voting';
import { Link } from 'react-router-dom';
import Globals from 'globals.json';

export interface HasVotedPageProps {
  voting: Voting;
}

const HasVotedPage: React.FC<HasVotedPageProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <img src={Globals.EVENT_IMAGE} className='bg-image' alt='' />
      <div className='has-voted-page'>
        <h3 className='site-header3'>{t('HasVotedPage.ThanksForVoting')}</h3>
        <h3 className='site-header3'>{t('HasVotedPage.AlreadyVoted')}</h3>
        <Link to='/'>{t('General.GoToFrontpage')}</Link>
      </div>
    </>
  );
};

export default HasVotedPage;
