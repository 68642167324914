import React, { useContext } from 'react';
import './ResultBestInSportsPage.scss';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'containers/app/App';
import BorderTitle from 'components/BorderTitle';
import { VotingStatus } from 'models/Voting/VotingStatus';
import ResultSportGrid from 'components/Result/ResultSportGrid';
import Globals from 'globals.json';

const ResultBestInSportsPage: React.FC = () => {
  const { t } = useTranslation();
  const { votingService, votingState } = useContext(AppContext);
  const usableVotings = votingState!.votings.filter(
    (voting) => voting.getStatus() === VotingStatus.RESULTS_READY
  );
  const latestUsableVoting =
    usableVotings.length > 0 ? usableVotings[0] : undefined;
  const hasBeenLoaded = latestUsableVoting
    ? latestUsableVoting.resultsLoaded
    : false;
  const votingId = latestUsableVoting ? latestUsableVoting.id : 0;

  React.useEffect(() => {
    if (!hasBeenLoaded && votingId > 0) {
      votingService!.getResults([votingId]);
    }
  }, [votingService, hasBeenLoaded, votingId]);

  if (!latestUsableVoting) {
    return <></>;
  }

  return (
    <div className='results-sports'>
      <img
        src={Globals.EVENT_IMAGE}
        className='results-sports__bg-image'
        alt=''
      />
      <div className='results-sports__container'>
        <BorderTitle
          className='results-sports__container__title'
          text={`${t('General.BestInSports')} ${latestUsableVoting!.year}`}
        />
        {latestUsableVoting.result &&
          latestUsableVoting.result!.bestInSports.length > 0 && (
            <ResultSportGrid
              title=''
              bestFromSportsCandidates={latestUsableVoting.result!.bestInSports}
            />
          )}
      </div>
    </div>
  );
};

export default ResultBestInSportsPage;
